<template>
    <!--Tutorials section-->
    <section class="tutorials">
        <!--Section main container-->
        <div class="section-main-wrapper">
            <!--Header title-->
            <div class="heading-wrapper">
                <h2>Tutorials</h2>
            </div>
            <!--Sub title and text-->
            <div class="text-content">
                <p class="sub-title">A Dedicated Channel for Tutorials That Showcase Every Functionality</p>
            </div>
            <!--Review message-->
            <div class="rating-message-wrapper rating-review-wrapper">
                <div class="image-customer item-span-two-rows">
                    <img src="../../assets/images/tutorial.png" alt="Tutorial Image" />
                </div>
                <p class="text">
                    We've invested in creating helpful video tutorials on YouTube and comprehensive guides on our
                    website, ensuring you fully understand and utilize our app's features. Additionally, we offer
                    articles on the scheduling business, providing you with valuable insights to enhance your
                    professional growth.
                </p>
                <div class="customer-message span-two-column padding-left">
                    <div class="ring-image">
                        <img src="../../assets/images/rml-rounds.png" alt="RML Ring" />
                    </div>
                    <div class="message">
                        <p>
                            “The YouTube tutorials were a game-changer for me. They helped me set up my online profile
                            perfectly and adjust the calendar just the way I wanted. Made everything so much easier and
                            clearer. Huge thanks for these awesome videos.”
                            <span>- Pamela Spriggs | Loctician</span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    name: "SalesTutorials",

    data: function () {
        return {};
    },
    mounted: function () {},
    computed: {},
};
</script>
