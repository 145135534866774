<template>
    <!--Scheduling solution section-->
    <section class="sales-scheduling-solution">
        <!--Section main container-->
        <div class="scheduling-solution-main-wrapper">
            <div class="scheduling-inner-wrapper">
                <!--Section title-->
                <div class="scheduling-title">
                    <h2 class="title">Appointment Scheduling Solution <br />for Stylists, Barbers, and Locticians</h2>
                </div>
                <!--Section content-->
                <div class="scheduling-wrapper">
                    <!--Section image-->
                    <div class="scheduling-ring-my-pro">
                        <img src="../../assets/images/rml-rmb-rms.png" alt="RMS RMB RML" />
                    </div>
                    <!--Section text-->
                    <div class="scheduling-text">
                        <h2 class="text-title">$9.99 per month</h2>
                        <p class="benefits">7 Days of Full Free Access</p>
                        <p class="benefits">1 bookable calendar</p>
                        <p class="benefits">100 SMS/mo.</p>
                        <p class="benefits last">Unlimited email notifications</p>
                        <p class="unlock-plan">
                            Unlock an Exclusive 20% Savings. Choose our Annual Plan and Pay Less for a Whole Year of
                            Unmatched Service.
                        </p>

                        <div class="get-started-wrapper">
                            <router-link class="sales-get-started" :to="{ path: '/get-started' }"
                                >Get started</router-link
                            >
                        </div>
                        <p class="custom-support">#1 for Custom Support. Free setup & data import. Cancel anytime.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    name: "SalesSchedulingSolution",

    data: function () {
        return {};
    },
    mounted: function () {},
    computed: {},
    methods: {},
};
</script>
