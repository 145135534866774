<template>
    <!--Account sign up-->
    <div class="account-signup">
        <div class="account-signup-wrapper">
            <div class="signup-title">
                <h1>{{ generalOptions.app_name }} | Business Account Sign Up</h1>
            </div>
            <div class="signup-form-wrapper">
                <div class="form-steps">
                    <div class="steps" v-for="formStep in formSteps" :key="formStep.id">
                        <div
                            class="step-separator"
                            :class="formStep.id === step ? 'active-color-app' : 'inactive-step'"
                        ></div>
                        <div class="step" :class="formStep.id === step ? 'active-step-text' : ''">
                            {{ formStep.title }}
                        </div>
                    </div>
                </div>
                <div class="main-wrapper">
                    <!--Account details formular-->
                    <form
                        class="account-details-form"
                        id="submit-form"
                        v-if="step === 1"
                        @submit.prevent="yourProfileDetails"
                    >
                        <h2 class="signup-form-title">Account Details</h2>
                        <div class="account-details">
                            <!--Owner First Name-->
                            <div class="form-input-wrap">
                                <input
                                    v-model.trim="salesSignupObj.firstName"
                                    class="form-input"
                                    name="first-name"
                                    type="text"
                                    :class="{ error: isValidatedField && salesSignupObj.firstName === '' }"
                                    :placeholder="
                                        isValidatedField ? 'Please enter Owner First Name' : 'Owner First Name'
                                    "
                                    required
                                />
                            </div>
                            <!--Owner Last Name-->
                            <div class="form-input-wrap">
                                <input
                                    v-model.trim="salesSignupObj.lastName"
                                    class="form-input"
                                    name="last-name"
                                    type="text"
                                    :class="{ error: isValidatedField && salesSignupObj.lastName === '' }"
                                    :placeholder="isValidatedField ? 'Please enter Owner Last Name' : 'Owner Last Name'"
                                    required
                                />
                            </div>
                            <!--Email-->
                            <div class="form-input-wrap">
                                <input
                                    v-model.trim="salesSignupObj.email"
                                    class="form-input"
                                    name="email"
                                    type="email"
                                    :class="{ error: isValidatedField && salesSignupObj.email === '' }"
                                    :placeholder="
                                        isValidatedField ? 'Please enter Login Email Address' : 'Login Email Address'
                                    "
                                    required
                                />
                            </div>
                            <!--Password-->
                            <div class="form-input-wrap">
                                <input
                                    v-model.trim="salesSignupObj.password"
                                    @keyup="validatePassword(salesSignupObj.password)"
                                    class="form-input"
                                    name="password"
                                    :type="showPassword ? 'text' : 'password'"
                                    :class="{ error: isValidatedField && salesSignupObj.password === '' }"
                                    :placeholder="isValidatedField ? 'Please enter Password' : 'Password'"
                                    required
                                />
                                <div class="show-hide-password">
                                    <button type="button" @click="toggleShow">
                                        <span class="">
                                            <i
                                                class="fa"
                                                :class="{ 'fa-eye-slash': showPassword, 'fa-eye': !showPassword }"
                                            ></i>
                                        </span>
                                    </button>
                                </div>
                                <span
                                    v-if="passwordErrorMessage !== ''"
                                    class="invalid-password-message"
                                    :class="{ error: passwordErrorMessage !== '' }"
                                >
                                    {{ passwordErrorMessage }}</span
                                >
                            </div>
                            <!--About Us-->
                            <div class="form-input-wrap">
                                <select
                                    class="form-select"
                                    name="about-us-select"
                                    v-model="salesSignupObj.aboutUs"
                                    :class="{
                                        error: isValidatedField && salesSignupObj.aboutUs === '',
                                        'select-color': salesSignupObj.aboutUs !== '',
                                    }"
                                    required
                                >
                                    <option value="" disabled>Where did you hear about us?</option>
                                    <option
                                        class="option-color"
                                        :value="referral.referralText"
                                        v-for="referral in referralSources"
                                        :key="referral.id"
                                    >
                                        {{ referral.referralText }}
                                    </option>
                                </select>
                            </div>
                            <!--Zip Code-->
                            <div class="form-input-wrap">
                                <input
                                    v-model.trim="salesSignupObj.zipCode"
                                    class="form-input"
                                    name="zip-code"
                                    type="number"
                                    :class="{ error: isValidatedField && salesSignupObj.zipCode === '' }"
                                    :placeholder="isValidatedField ? 'Please enter Zip Code' : 'Zip Code'"
                                    required
                                />
                            </div>
                        </div>
                        <!--Your Bussines formular-->
                        <h2 class="signup-form-title">Your Business</h2>
                        <div class="your-business-form">
                            <!--Business Name-->
                            <div class="form-input-wrap">
                                <input
                                    v-model.trim="salesSignupObj.businessName"
                                    class="form-input"
                                    name="business-name"
                                    type="text"
                                    placeholder="Business Name"
                                    :class="{ error: isValidatedField && salesSignupObj.businessName === '' }"
                                    :placeholder="isValidatedField ? 'Please enter Business Name' : 'Business Name'"
                                    required
                                />
                            </div>
                            <!--Country-->
                            <div class="form-input-wrap">
                                <select
                                    class="form-select"
                                    name="country-select"
                                    v-model="salesSignupObj.country"
                                    :class="{
                                        error: isValidatedField && salesSignupObj.country === '',
                                        'select-color': salesSignupObj.country !== '',
                                    }"
                                    required
                                >
                                    <option value="" disabled>Country</option>
                                    <option
                                        class="option-color"
                                        :value="country"
                                        v-for="country in countries"
                                        :key="country.id"
                                    >
                                        {{ country.name }}
                                    </option>
                                </select>
                            </div>
                            <!--Business Address One-->
                            <div class="form-input-wrap">
                                <input
                                    v-model.trim="salesSignupObj.businessAddressOne"
                                    class="form-input"
                                    name="business-address-one"
                                    type="text"
                                    :class="{ error: isValidatedField && salesSignupObj.businessAddressOne === '' }"
                                    :placeholder="
                                        isValidatedField
                                            ? 'Please enter Business Address Line 1'
                                            : 'Business Address Line 1'
                                    "
                                    required
                                />
                            </div>
                            <!--Business Address Two-->
                            <div class="form-input-wrap">
                                <input
                                    v-model.trim="salesSignupObj.businessAddressTwo"
                                    class="form-input"
                                    name="business-address-two"
                                    type="text"
                                    :placeholder="
                                        isValidatedField
                                            ? 'Please enter Business Address Line 2'
                                            : 'Business Address Line 2'
                                    "
                                />
                            </div>
                            <!--Mobile Phone-->
                            <div class="form-input-wrap">
                                <input
                                    v-model.trim="salesSignupObj.mobilePhone"
                                    class="form-input"
                                    name="mobile-phone"
                                    type="number"
                                    :class="{ error: isValidatedField && salesSignupObj.mobilePhone === '' }"
                                    :placeholder="isValidatedField ? 'Please enter Mobile Phone' : 'Mobile Phone'"
                                    required
                                />
                            </div>
                            <!--Business Phone-->
                            <div class="form-input-wrap">
                                <input
                                    v-model.trim="salesSignupObj.businessPhone"
                                    class="form-input"
                                    name="business-phone"
                                    type="number"
                                    :class="{ error: isValidatedField && salesSignupObj.businessPhone === '' }"
                                    :placeholder="isValidatedField ? 'Please enter Business Phone' : 'Business Phone'"
                                    required
                                />
                            </div>
                            <!--Business Category-->
                            <div class="form-input-wrap">
                                <select
                                    class="form-select"
                                    name="business-category-select"
                                    v-model="salesSignupObj.businessCategory"
                                    :class="{
                                        error: isValidatedField && salesSignupObj.businessCategory === '',
                                        'select-color': salesSignupObj.businessCategory !== '',
                                    }"
                                    required
                                >
                                    <option value="" disabled>Business Category / Profession</option>
                                    <option
                                        class="option-color"
                                        :value="profession"
                                        v-for="profession in professions"
                                        :key="profession.id"
                                    >
                                        {{ profession.profession }}
                                    </option>
                                </select>
                            </div>
                            <!--Business Location-->
                            <div class="form-input-wrap">
                                <select
                                    class="form-select"
                                    name="business-locations"
                                    v-model="salesSignupObj.businessLocation"
                                    :class="{
                                        error: isValidatedField && salesSignupObj.businessLocation === '',
                                        'select-color': salesSignupObj.businessLocation !== '',
                                    }"
                                    required
                                >
                                    <option value="" disabled>Please enter Where Do You Perform Your Services</option>
                                    <option
                                        class="option-color"
                                        :value="location.businessLocationValue"
                                        v-for="location in businessLocations"
                                        :key="location.id"
                                    >
                                        {{ location.businessLocationValue }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </form>
                    <!--Marketing & Promotional Opportunities-->
                    <div class="marketing-promotional-opportunities" v-if="step === 2">
                        <h2 class="signup-form-title">Boost Your Business</h2>
                        <p class="signup-form-info">
                            Explore our range of optional marketing and promotional services to enhance your business.
                            Select any that interest you, and we'll follow up for a detailed discussion and planning,
                            with no commitment at this stage. It's all about finding the right fit for your business
                            growth.
                        </p>

                        <div class="checkbox-inputs-container">
                            <div class="input-checkbox" v-for="(checkbox, k) in checkBoxList" :key="checkbox.id">
                                <div class="input-box">
                                    <input
                                        v-model="checkboxOportunityList"
                                        type="checkbox"
                                        :id="checkbox.id + '-checkbox'"
                                        :name="checkbox.title"
                                        :value="checkbox.title"
                                        :checked="k === 0"
                                    />
                                </div>
                                <label :for="checkbox.id + '-checkbox'" class="checkbox-label-wrap">
                                    <span class="checkbox-title">{{ checkbox.title }}</span>
                                    <span>
                                        {{ checkbox.message.replaceAll("[app_name]", generalOptions.app_name) }}
                                    </span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <!--Business Information-->
                    <div class="business-information" v-if="step === 3">
                        <div>
                            <h2 class="signup-form-title">Let’s Tailor Your Experience</h2>
                            <p class="signup-form-info">
                                Welcome to the final step before we kickstart your journey with
                                {{ generalOptions.app_name }}. We're excited to learn more about your business needs and
                                how we can best support your growth. Please fill out the information below to help us
                                customize your setup and services. Remember, there's no commitment at this stage, and
                                we're here to ensure a smooth transition and successful start.
                            </p>
                            <div class="final-step-container">
                                <!--Online Payment System Preference?-->
                                <div class="payment-preference-wrapp">
                                    <p class="payment-title">Online Payment System Preference?</p>
                                    <p>
                                        Do you intend to utilize the Online Payment for deposits, no-show fees, or
                                        rescheduling charges? If so, please select your preferred payment provider.
                                    </p>
                                    <p
                                        v-if="isRadioSelected && paymentMethod === ''"
                                        :class="{ error: isRadioSelected }"
                                    >
                                        Please select an option from below:
                                    </p>
                                    <div class="payment-methods-wrapp">
                                        <div
                                            class="input-checkbox"
                                            v-for="(payment, k) in paymentMethods"
                                            :key="payment.id"
                                        >
                                            <div class="input-box">
                                                <input
                                                    v-model="paymentMethod"
                                                    type="radio"
                                                    :id="payment.id + '-payment'"
                                                    :name="payment.title"
                                                    :value="payment.title"
                                                    required
                                                />
                                            </div>
                                            <label :for="payment.id + '-payment'" class="checkbox-label-wrap">
                                                <span class="checkbox-title">{{ payment.title }}</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <!--Data Migration Assistance?-->
                                <div class="payment-preference-wrapp">
                                    <p class="payment-title">Data Migration Assistance?</p>
                                    <p>
                                        Are you switching from another platform/app and need help migrating your data?
                                    </p>
                                    <div class="form-input-wrap">
                                        <select
                                            class="form-select"
                                            name=">data-migration-assistance"
                                            v-model="dataMigrationAssistanceModel"
                                            :class="{
                                                'select-color': dataMigrationAssistanceModel !== '',
                                            }"
                                            required
                                        >
                                            <option value="" disabled>Please select data migration assistance</option>
                                            <option
                                                class="option-color"
                                                :value="dataMigration.title"
                                                v-for="dataMigration in dataMigrationAssistance"
                                                :key="dataMigration.id"
                                            >
                                                {{ dataMigration.title }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <!-- Onboarding and Training Schedule?-->
                                <div class="payment-preference-wrapp">
                                    <p class="payment-title">Onboarding and Training Schedule?</p>
                                    <p class="signup-form-info">
                                        When is a good time for us to call you to schedule your first free onboarding
                                        and training Zoom meeting?
                                    </p>
                                    <div class="form-input-wrap">
                                        <input
                                            v-model.trim="onboarding"
                                            @input="limitCharacters"
                                            class="form-input"
                                            name="Onboarding"
                                            type="text"
                                            maxlength="200"
                                            placeholder="Please enter Onboarding and Training Schedule "
                                        />
                                    </div>
                                </div>
                                <!--Additional Training Inquiry?-->
                                <div class="payment-preference-wrapp">
                                    <p class="payment-title">Additional Training Inquiry?</p>
                                    <p>
                                        Interested in in-depth, individual personnel training? Let us know, and we'll
                                        provide the details and costs.
                                    </p>
                                    <p
                                        v-if="isRadioSelected && additionalTraining === ''"
                                        :class="{ error: isRadioSelected }"
                                    >
                                        Please select an option from below:
                                    </p>
                                    <div class="payment-methods-wrapp">
                                        <div
                                            class="input-checkbox"
                                            v-for="(training, k) in additionalTrainingInquiry"
                                            :key="training.id"
                                        >
                                            <div class="input-box">
                                                <input
                                                    v-model="additionalTraining"
                                                    type="radio"
                                                    :id="training.id + '-training'"
                                                    :name="training.title"
                                                    :value="training.title"
                                                />
                                            </div>
                                            <label :for="training.id + '-training'" class="checkbox-label-wrap">
                                                <span class="checkbox-title">{{ training.title }}</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--Confirm Phone Number-->
                    <div v-if="step === 4" class="confirm-phone-number">
                        <h2 class="signup-form-title">Confirm Your Phone Number</h2>
                        <p class="signup-form-info">
                            To confirm the identity of your account ( associated with
                            <strong>{{ salesSignupObj.mobilePhone }}</strong
                            >) we sent you an SMS with a validation code.
                        </p>

                        <div class="form-input-wrap confirmation-code">
                            <input
                                v-model.trim="phoneCheckCode"
                                class="form-input"
                                type="text"
                                autocomplete="off"
                                v-on:keyup.13="checkVerificationCode"
                                placeholder="Please enter the validation code"
                            />
                            <button
                                id="check-verification-code"
                                type="button"
                                class="sales-get-started"
                                @click="checkVerificationCode"
                                tabindex="10"
                            >
                                Continue
                            </button>
                        </div>
                        <div class="book-action-links">
                            <a class="book-action-link main-text-link" href="#" @click="verifyPhoneNumber"
                                >Resend Code</a
                            >
                        </div>

                        <div v-if="phoneCheckMessage" v-html="phoneCheckMessage"></div>
                    </div>
                    <!--Navigation form buttons-->
                    <div class="signup-navigation-btns-wrapper">
                        <div class="signup-back">
                            <router-link :to="{ path: '/get-started' }" v-if="step === 1">
                                <img src="../../assets/images/long-arrow.png" alt="Left Arrow"
                            /></router-link>
                            <div class="" @click="decrementStep" v-if="step > 1">
                                <img src="../../assets/images/long-arrow.png" alt="Left Arrow" />
                            </div>
                        </div>
                        <button
                            v-if="this.step !== 4"
                            class="sales-get-started"
                            type="submit"
                            form="submit-form"
                            @click="incrementStep()"
                        >
                            {{ step === 3 ? " Finish & Launch" : " Next" }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="terms-privacy-wrapp">
            By continuing you are indicating that you have read and agree to the
            <a class="main-text-link" target="_blank" href="https://www.ringmystylist.com/terms-and-condition"
                >Terms of Service</a
            >
            &
            <a class="main-text-link" target="_blank" href="https://www.ringmystylist.com/privacy-policy"
                >Privacy Policy</a
            >
        </div>
    </div>
</template>
<script>
import api from "./../../services/api_endpoints.js";
import auth from "./../../services/auth.js";
import eventBus from "./../../services/event_bus.js";
import store from "./../../services/vuex.js";

export default {
    name: "SalesAccountSignup",

    data: function () {
        return {
            formSteps: [
                { id: 1, title: "Your Profile Details" },
                { id: 2, title: "Marketing & Promotional Opportunities" },
                { id: 3, title: "Business Information" },
            ],
            step: 1,
            checkBoxList: [
                {
                    id: 0,
                    title: "Directory Promotion",
                    message:
                        "Get Featured in the [app_name] Main Directory. Want to increase visibility for a nominal monthly fee?",
                },
                {
                    id: 1,
                    title: "Logo Design Services",
                    message: "Need a Logo That Reflects Your Brand's Culture and Direction? Let us help you design it.",
                },
                {
                    id: 2,
                    title: "Website Development Assistance",
                    message:
                        "Understand and Leverage the Power of a Professional Website. Interested in website creation or enhancement?",
                },
                {
                    id: 3,
                    title: "Social Media Presence Boost",
                    message:
                        "Enhance Your Online Presence on Instagram, YouTube, and Facebook. Need assistance in establishing or improving your social media profiles?",
                },
                {
                    id: 4,
                    title: "Sponsored Online Campaigns",
                    message:
                        "Expand Your Reach with Sponsored Campaigns on Social Platforms. Interested in tailored advertising on Instagram, Facebook, or YouTube?",
                },
            ],
            checkboxOportunityList: [],
            paymentMethods: [
                { id: 0, title: "Stripe" },
                { id: 1, title: "Square" },
                { id: 2, title: "None" },
            ],
            paymentMethod: "",
            salesSignupObj: {
                firstName: "",
                lastName: "",
                email: "",
                password: "",
                aboutUs: "",
                zipCode: "",
                businessName: "",
                country: "",
                businessAddressOne: "",
                businessAddressTwo: "",
                mobilePhone: "",
                businessPhone: "",
                businessCategory: "",
                businessLocation: "",
            },
            formSubmitted: false,
            isValidatedField: false,
            referralSources: [
                { id: 0, referralText: "Facebook" },
                { id: 1, referralText: "Referred by a friend" },
                { id: 2, referralText: "Instagram" },
                { id: 3, referralText: "Referred by my client" },
                { id: 4, referralText: "Google" },
                { id: 5, referralText: "App Store" },
                { id: 6, referralText: "Other" },
            ],
            businessLocations: [
                { id: 0, businessLocationValue: "Only at My Business Location" },
                { id: 1, businessLocationValue: "Only at the Client’s Location" },
                { id: 2, businessLocationValue: "Flexible: Both My Location and Client's Location" },
            ],
            professions: [],
            countries: [],
            prefixes: [],
            showPassword: false,
            dataMigrationAssistance: [
                { id: 0, title: "Yes, I'm interested in the free migration service." },
                { id: 1, title: "Yes, I'm interested in the comprehensive migration service, contact us." },
                { id: 2, title: "No, thank you." },
            ],
            dataMigrationAssistanceModel: "",
            onboarding: "",
            additionalTraining: "",
            additionalTrainingInquiry: [
                { id: 0, title: "Yes" },
                { id: 1, title: "No" },
            ],
            isRadioSelected: false,
            role: "stylist",
            subscriptionPlan: "monthly",
            sms_optin: 1,
            confirmedPhone: false,
            phoneCheckMessage: "",
            phoneCheckCode: "",
            passwordErrorMessage: "",
        };
    },
    mounted: function () {
        this.getProfessions();
        this.getCountries();
        this.scrollTop();
        this.numberOfCalendars = sessionStorage.getItem("users-number", 1);
        eventBus.$on("user_profile_loaded", this.onLoggedIn);
    },
    beforeDestroy() {
        eventBus.$off("user_profile_loaded", this.onLoggedIn);
    },
    computed: {
        numberOfCalendars: {
            get: function () {
                return store.state.numberOfCalendars;
            },
            set: function (value) {
                store.commit("setSalesNumberOfCalendars", value);
            },
        },
    },
    methods: {
        incrementStep() {
            if (this.step === 1) {
                if (this.validateInput()) {
                    this.yourProfileDetails();
                } else {
                    return;
                }
            }

            if (this.step === 2) {
                this.oportunityList();
            }

            if (this.step === 3) {
                this.yourExperience();
                return;
            }

            if (this.step >= 1 && this.step <= 2) {
                this.step = this.step + 1;
            }
        },
        decrementStep() {
            if (this.step >= 2) {
                this.step = this.step - 1;
            }
        },
        getProfessions: function () {
            this.$http.get(api.actions.get_professions + "/" + this.generalOptions.app_id).then(
                function (response) {
                    if (typeof response.data.professions != "undefined") {
                        this.professions = response.data.professions;
                        if (this.professions.length == 1) {
                            this.selectedProfession = this.professions[0];
                        }
                    }
                }.bind(this),
                function () {
                    return false;
                }
            );
        },
        getCountries: function () {
            this.$http.get(api.actions.get_countries).then(
                function (response) {
                    if (typeof response.data.countries != "undefined") {
                        this.countries = response.data.countries;
                    }
                }.bind(this),
                function () {
                    return false;
                }
            );
        },
        yourProfileDetails() {
            this.formSubmitted = true;

            if (!this.validateInput()) {
                return;
            }

            let data = {
                app_id: this.generalOptions.app_id + "0",
                sales_signup_obj: this.salesSignupObj,
                number_of_calendars: this.numberOfCalendars,
            };

            //Send email with profile details
            this.$http.post(api.actions.send_register_form_email, data).then((response) => {
                if (response.status === 200) {
                    this.formSubmitted = true;
                }
            });
        },
        validateInput() {
            this.isValidatedField = true;
            for (const field in this.salesSignupObj) {
                if (field === "businessAddressTwo") {
                    return true;
                }
                if (this.salesSignupObj[field] === null || this.salesSignupObj[field] === "") {
                    return false;
                }

                if (field === "email" && !this.isValidEmail(this.salesSignupObj[field])) {
                    return false;
                }

                if (field === "password" && !this.isValidPassword(this.salesSignupObj[field])) {
                    return false;
                }

                this.isValidatedField = false;
            }

            return true;
        },
        isValidEmail(email) {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return emailRegex.test(email);
        },
        isValidPassword(password) {
            const passwordRegex = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
            // Minimum 8 characters, at least one uppercase letter, one lowercase letter, one digit, and one special character
            return passwordRegex.test(password);
        },
        validatePassword(password) {
            const uppercaseRegex = /[A-Z]/; // At least one uppercase letter
            const lowercaseRegex = /[a-z]/; // At least one lowercase letter
            const digitRegex = /\d/; // At least one digit
            const specialCharRegex = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/; // At least one special character

            let missingRequirements = [];

            if (password.length < 8) {
                missingRequirements.push("at least 8 characters");
            }
            if (!uppercaseRegex.test(password)) {
                missingRequirements.push("at least one uppercase letter");
            }
            if (!lowercaseRegex.test(password)) {
                missingRequirements.push("at least one lowercase letter");
            }
            if (!digitRegex.test(password)) {
                missingRequirements.push("at least one digit");
            }
            if (!specialCharRegex.test(password)) {
                missingRequirements.push("at least one special character except dot");
            }

            if (missingRequirements.length === 0) {
                this.passwordErrorMessage = "";
            } else {
                this.passwordErrorMessage = "Password must contain " + missingRequirements.join(", ") + ".";
            }
        },

        toggleShow() {
            this.showPassword = !this.showPassword;
        },
        scrollTop() {
            setTimeout(() => {
                window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                });
            }, 200);
        },
        limitCharacters() {
            if (this.onboarding.length > 200) {
                this.onboarding = this.onboarding.substring(0, 200);
            }
        },

        oportunityList() {
            if (this.checkboxOportunityList.length === 0) {
                return;
            }
        },
        yourExperience() {
            this.isRadioSelected = true;

            if (this.paymentMethod === "") {
                return;
            }

            if (this.additionalTraining === "") {
                return;
            }

            let data = {
                app_id: this.generalOptions.app_id + "0",
                oportunity: this.checkboxOportunityList,
                payment_method: this.paymentMethod,
                data_migration_assistance: this.dataMigrationAssistanceModel,
                on_boarding: this.onboarding,
                additional_training: this.additionalTraining,
                sales_signup_obj: this.salesSignupObj,
                number_of_calendars: this.numberOfCalendars,
            };

            //Send all the Sign Up data to email
            this.$http.post(api.actions.send_register_form_email, data).then((response) => {
                if (response.status === 200) {
                    this.formSubmitted = true;
                    this.onRegister();
                }
            });
        },

        onRegister() {
            if (!this.confirmedPhone) {
                let d = {
                    email: this.salesSignupObj.email,
                    phone: this.salesSignupObj.mobilePhone,
                    phone_prefix: this.salesSignupObj.country.dial_code,
                };

                this.$http.post(api.actions.check_existing_user_data, d).then(
                    function (response) {
                        if (response.data.error != true) {
                            this.verifyPhoneNumber();
                        }
                    }.bind(this),
                    function () {
                        return false;
                    }
                );

                return;
            }
            let data = {
                full_name: this.salesSignupObj.firstName + " " + this.salesSignupObj.lastName,
                email: this.salesSignupObj.email,
                password: this.salesSignupObj.password,
                role: this.role,
                zip: this.salesSignupObj.zipCode,
                phone: this.salesSignupObj.mobilePhone,
                phone_prefix: this.salesSignupObj.country.dial_code,
                sms_optin: this.sms_optin,
                app_id: this.generalOptions.app_id,
                business_name: this.salesSignupObj.businessName,
                profession_ids: [this.salesSignupObj.businessCategory.id],
                referral_code: this.salesSignupObj.aboutUs,
                referral_source: "",
                business_address_street: "",
                business_address_city: "",
                business_address_state: "",
                business_address_country: this.salesSignupObj.businessLocation,
            };

            auth.register(data);
        },

        verifyPhoneNumber() {
            var data = {
                phone_prefix: this.salesSignupObj.country.dial_code,
                phone: this.salesSignupObj.mobilePhone,
            };

            this.phoneCheckMessage = "";

            this.$http.post(api.actions.send_phone_verify_code, data).then((response) => {
                if (response.error == true) {
                    this.phoneCheckMessage = '<div class="alert alert-danger">There is an error</div>';
                    return;
                }

                if (response.data.error == true) {
                    this.phoneCheckMessage = '<div class="alert alert-danger">' + response.data.message + "</div>";
                }

                this.phoneCheckMessage = '<div class="alert sms-code-success">The code has been sent</div>';

                this.step = 4;
            });
        },
        checkVerificationCode() {
            var data = {
                phone: this.salesSignupObj.mobilePhone,
                code: this.phoneCheckCode,
            };

            this.$http.post(api.actions.verify_phone_code, data).then((response) => {
                if (response.error == true) {
                    this.phoneCheckMessage = '<div class="alert alert-danger">There is an error</div>';
                    return;
                }

                if (response.data.error == true) {
                    this.phoneCheckMessage = '<div class="alert alert-danger">' + response.data.message + "</div>";
                } else {
                    this.confirmedPhone = true;
                    this.onRegister();
                }
            });
        },
        onLoggedIn: function () {
            this.$router.push("/app");
            window.location.reload();
            this.scrollTop();
        },
    },
};
</script>
