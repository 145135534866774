<template>
    <!--Price per stylist calculator-->
    <div class="bookable-calendars-wrapper">
        <div class="bookable-title">
            <h2>How Many Bookable Calendars Do You Need for Your Business?</h2>
        </div>
        <div class="calendar-numbers">
            <div
                class="nr-wrapp"
                v-for="number in numbers"
                :key="number"
                @click="calculatePrice(number)"
                :class="{ 'active-number': numberOfCalendars === number }"
            >
                {{ number === 1 ? "Just me" : number === 6 ? number + "+" : number }}
            </div>
        </div>
        <div class="calendar-cost">
            <h2>You’ll pay:</h2>
            <p class="text-subtitle">
                $<span>{{ totalPrice }}</span> per month
            </p>
            <p class="benefits">
                {{ numberOfCalendars }} {{ numberOfCalendars == 6 ? "or more" : "" }} bookable*
                {{ numberOfCalendars > 1 ? "calendars" : "calendar" }}
            </p>
            <p class="bookable-explanation">
                A bookable calendar refers to an individual's personal schedule within your business.*
            </p>
            <div class="get-started-wrapper">
                <router-link
                    class="sales-get-started"
                    :class="appDetails[salesSelectedApp].appClass"
                    :to="{ path: '/sign-up' }"
                    >Get Started</router-link
                >
            </div>
        </div>
    </div>
</template>
<script>
import store from "./../../services/vuex.js";

export default {
    name: "SalesBookableCalendars",

    data: function () {
        return {
            numbers: [1, 2, 3, 4, 5, 6],
            totalPrice: 0,
            price: 9.99,
            appDetails: {
                1: {
                    appName: "Ring My Stylist",
                    appClass: "rms",
                },
                2: {
                    appName: "Ring My Barber",
                    appClass: "rmb",
                },
                5: {
                    appName: "Ring My Loctician",
                    appClass: "rml",
                },
            },
        };
    },
    mounted: function () {
        this.calculatePrice(this.numberOfCalendars);
        this.numberOfCalendars = parseInt(sessionStorage.getItem("users-number", 1));
    },
    computed: {
        salesSelectedApp: {
            get: function () {
                return store.state.salesSelectedApp;
            },
            set: function (value) {
                store.commit("setSalesSelectedApp", value);
            },
        },
        numberOfCalendars: {
            get: function () {
                return store.state.numberOfCalendars;
            },
            set: function (value) {
                store.commit("setSalesNumberOfCalendars", value);
            },
        },
    },
    methods: {
        calculatePrice(number) {
            this.totalPrice = number * this.price;
            this.numberOfCalendars = number;

            sessionStorage.setItem("users-number", number);
        },
    },
};
</script>
