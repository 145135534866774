<template>
    <!--Get Started Page-->
    <div class="get-started-page">
        <!--Sales header-->
        <sales-header></sales-header>
        <div class="get-started-section">
            <div class="get-started-title">
                <h1>
                    Select Your Perfect Fit <br />
                    Choose the Platform Tailored to Your Business
                </h1>
            </div>
            <!--Ring My Stylist Ring My Barber Ring My Loctician buttons-->
            <sales-rms-rmb-rml-buttons></sales-rms-rmb-rml-buttons>
            <!--Price per stylist calculator-->
            <sales-bookable-calendars></sales-bookable-calendars>
            <!--What's included section-->
            <sales-whats-included></sales-whats-included>
            <!--Transparent pricing section-->
            <sales-transparent-pricing></sales-transparent-pricing>
            <!--Pricing FAQs-->
            <sales-pricing-faqs></sales-pricing-faqs>
        </div>
        <!--Sales footer-->
        <sales-footer></sales-footer>
    </div>
</template>
<script>
import SalesHeader from "./sales/sales_header.vue";

import SalesRmsRmbRmlButtons from "./sales/sales_rms_rmb_rml_buttons.vue";
import SalesBookableCalendars from "./sales/sales_bookable_calendars.vue";
import SalesWhatsIncluded from "./sales/sales_whats_included.vue";
import SalesTransparentPricing from "./sales/sales_transparent_pricing.vue";
import SalesPricingFaqs from "./sales/sales_pricing_faqs.vue";

import SalesFooter from "./sales/sales_footer.vue";

export default {
    name: "SalesGetStartedPage",

    data: function () {
        return {};
    },
    mounted: function () {
        this.scrollTop();
    },
    computed: {},
    methods: {
        scrollTop() {
            setTimeout(() => {
                window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                });
            }, 200);
        },
    },
    components: {
        SalesHeader,
        SalesRmsRmbRmlButtons,
        SalesBookableCalendars,
        SalesWhatsIncluded,
        SalesTransparentPricing,
        SalesPricingFaqs,
        SalesFooter,
    },
};
</script>
