<template>
    <!--Marketing section-->
    <section class="marketing">
        <!--Section main container-->
        <div class="section-main-wrapper no-background">
            <!--Header title-->
            <div class="heading-wrapper">
                <h2>Marketing</h2>
            </div>
            <!--Sub title and text-->
            <div class="text-content">
                <p class="sub-title">Boost Your Marketing: Widget, Offers, Custom Messages, and More</p>
                <p class="text">
                    Enhance your marketing with our tools: easily integrate a booking widget on your website, send mass
                    messages to clients, create special offers, and customize client notifications. These features are
                    designed to grow your client base and strengthen your brand, all while saving you time.
                </p>
            </div>
            <!--Review message-->
            <div class="rating-message-wrapper">
                <div class="image-customer"></div>
                <div class="customer-message span-two-column">
                    <div class="ring-image">
                        <img src="../../assets/images/rmb-rounds.png" alt="RMB Image" />
                    </div>
                    <div class="message">
                        <p>
                            “I had a personal emergency and needed to inform my 500+ clients that I couldn't take
                            appointments next week. Thanks to the mass text message feature, I was able to notify
                            everyone quickly and efficiently. It really saved me from a lot of stress and helped avoid
                            upsetting my clients.”
                            <span>- JefeBlendz | Barber Shop</span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    name: "SalesMarketing",

    data: function () {
        return {};
    },
    mounted: function () {},
    computed: {},
};
</script>
