<template>
    <!--Mobile Hamburger menu-->
    <div class="mobile-hamburger-menu" :class="{ 'move-left': !isOpen }">
        <div class="overlay" v-if="isOpen" @click="toggleMenu"></div>
        <div class="menu-wrapper" :class="{ 'menu-open': isOpen }">
            <div :class="{ 'hamburger-container': isOpen }">
                <div class="hamburger" @click="toggleMenu">
                    <div :class="{ open: isOpen }" class="hamburger-line"></div>
                    <div :class="{ open: isOpen }" class="hamburger-line"></div>
                    <div :class="{ open: isOpen }" class="hamburger-line"></div>
                </div>
            </div>
            <nav :class="{ open: isOpen }" class="menu">
                <ul>
                    <li @click="toggleMenu">
                        <router-link class="home" :to="{ path: '/' }">Home </router-link>
                    </li>
                    <li @click="toggleMenu">
                        <a class="login" :href="generalOptions.booking_url + 'app/login'">Log In </a>
                    </li>
                    <li @click="toggleMenu">
                        <router-link class="signup" :to="{ path: '/get-started' }">Sign Up</router-link>
                    </li>
                </ul>
            </nav>
        </div>
    </div>
</template>
<script>
export default {
    name: "Hamburger",

    data: function () {
        return { isOpen: false };
    },
    mounted: function () {},
    computed: {},

    components: {},

    methods: {
        toggleMenu() {
            this.isOpen = !this.isOpen;

            if (this.isOpen) {
                document.body.classList.add("is-open");
            } else {
                document.body.classList.remove("is-open");
            }
        },
    },
};
</script>
