import Vue from "vue";
import Vuex from "vuex";
import VueRouter from "vue-router";
import VueResource from "vue-resource";
import Toasted from "vue-toasted";
import dotenv from "dotenv";
import App from "./App.vue";

import Sales from "./components/sales";
import SalesGetStartedPage from "./components/sales_get_started_page";
import SalesSignupPage from "./components/sales_signup";

import VueSweetalert2 from "vue-sweetalert2";
import VueLazyLoad from "vue-lazyload";
import api from "./services/api_endpoints";
import auth from "./services/auth.js";
import eventBus from "./services/event_bus.js";
import store from "./services/vuex.js";
import message from "./services/messages.js";
import config from "./services/config.js";
import "bootstrap/dist/css/bootstrap.css";
import "font-awesome/css/font-awesome.css";
import "./ml";
import moment from "moment";
import VTooltip from "v-tooltip";

Vue.config.productionTip = false;
Vue.use(VueRouter);
Vue.use(Vuex);
Vue.use(VueResource);
Vue.use(Toasted);
Vue.use(VueSweetalert2);
Vue.use(VueLazyLoad);
Vue.use(VTooltip);

dotenv.config();

/* Get the general app config */
Vue.use(config);

/* Register routes */
const routes = [
    { path: "/", component: Sales },
    { path: "/get-started", component: SalesGetStartedPage },
    { path: "/sign-up", component: SalesSignupPage },
];

const router = new VueRouter({
    mode: "history",
    routes: routes,
});

router.beforeEach((to, from, next) => {
    let token = JSON.parse(localStorage.getItem("token"));

    if (auth.private_routes.indexOf(to.path) !== -1 && !token) {
        router.push("/");
        return false;
    }

    if (auth.private_admin_routes.indexOf(to.path) !== -1 && !token) {
        router.push("/app/login");
        eventBus.$emit("user_logged_out");
        return false;
    }

    let user = JSON.parse(localStorage.getItem("user"));

    if (
        auth.private_admin_routes.indexOf(to.path) !== -1 &&
        to.path != "/app/subscription-payments" &&
        typeof user == "object" &&
        user !== null && // Check if user is not null
        user.role == "stylist" &&
        user.details.subscription &&
        !user.details.subscription.active
    ) {
        router.push("/app/subscription-payments");
        return false;
    }

    if (to.path.indexOf("/app") != -1) {
        eventBus.$emit("admin_route_loaded");
    }

    if (token && new Date().getTime() / 1000 >= token.expire_time) {
        auth.refreshToken(next);
    } else {
        next();
    }
});

/* Auth and http interceptors */
auth.http = Vue.http;
auth.api = api;
auth.toasted = Vue.toasted;
auth.eventBus = eventBus;
auth.message = message;
auth.store = store;
auth.generalOptions = Vue.prototype.generalOptions;

Vue.http.interceptors.push(function (request, next) {
    let token = JSON.parse(localStorage.getItem("token"));

    if (!request.noApiKey) {
        request.headers.map["apikey"] = [api.API_KEY];
    }

    if (token && !request.noApiKey) {
        request.headers.map["Authorization"] = ["Bearer " + token.access_token];
    }

    next(function (response) {
        switch (response.status) {
            case 403:
            case 404:
            case 422:
                Vue.toasted.show(api.REQUEST_ERROR, {
                    type: "error",
                    position: "top-center",
                    duration: 2000,
                });
                break;

            case 401:
                auth.logout(false);

                if (request.url.indexOf("/oauth/token") == -1) {
                    router.push("/app/login");
                }
                break;
        }

        if (typeof response.data.error != "undefined" && response.data.error === true && !request.noError) {
            Vue.toasted.show(response.data.message, {
                type: "error",
                position: "top-center",
                duration: 2000,
            });
        }
    });
});

// global filters
Vue.filter("phoneDashFormat", function (phone) {
    if (!phone) return "";
    phone = phone.toString();
    if (phone == null || phone.length < 10) {
        return phone;
    }
    var part1 = phone.substring(0, 3);
    var part2 = phone.substring(3, 6);
    var part3 = phone.substring(6, 11);
    return part1 + "-" + part2 + "-" + part3;
});
Vue.filter("capitalize", function (value) {
    if (!value) return "";
    value = value.toString();
    return value.charAt(0).toUpperCase() + value.slice(1);
});
Vue.filter("monthDateFormat", function (date) {
    if (!date) return "";
    return moment(date).format("MMMM Do");
});
Vue.filter("dateFormat", function (date) {
    if (!date) return "";
    return moment(date).format("D MMM");
});
Vue.filter("dateFormatUS", function (date) {
    if (!date) return "";
    return moment(date).format("MMM D");
});

Vue.filter("dateFullFormat", function (date) {
    if (!date) return "";
    return moment(date).format("DD/MM/YYYY");
});

Vue.filter("hourMinutesFormat", function (minutes) {
    if (!minutes) return "N/A";

    var hours = minutes / 60;
    var rhours = Math.floor(hours);
    minutes = (hours - rhours) * 60;
    var rminutes = Math.round(minutes);
    return (rhours ? rhours + "h " : "") + rminutes + " min";
});

Vue.filter("formatClientDate", function (value) {
    let today = moment().format("YYYY-MM-DD");
    let tomorrow = moment().add(1, "days");

    if (today == value) {
        return "Today";
    }

    if (tomorrow == value) {
        return "Tomorrow";
    }

    if (value) {
        return moment(String(value)).format("dddd, MMMM DD ,YYYY");
    }
});

/* Mount the main app component */
new Vue({
    render: (h) => h(App),
    router,
}).$mount("#app");
