<template>
    <!--Online Booking section-->
    <section class="online-booking">
        <!--Section main container-->
        <div class="section-main-wrapper">
            <!--Header title-->
            <div class="heading-wrapper">
                <h2>Online Booking</h2>
            </div>
            <!--Sub title and text-->
            <div class="text-content">
                <p class="sub-title">Enable Your Clients to View and Book Your Services Online with Ease</p>
                <p class="text">
                    Our online booking system offers 24/7 convenience, allowing clients to schedule your services
                    anytime, increasing your reservations and client satisfaction. It's not just about bookings, it's
                    about providing a flexible, client-friendly experience.
                </p>
            </div>
            <!--Review message-->
            <div class="rating-message-wrapper">
                <div class="image-customer"></div>
                <div class="customer-message span-two-column">
                    <div class="ring-image">
                        <img src="../../assets/images/rmb-rounds.png" alt="RMB Image" />
                    </div>
                    <div class="message">
                        <p>
                            “I just booked an appointment at midnight using the online system, and it was a breeze. So
                            happy I can schedule services on my own time without waiting for business hours. ”
                            <span>- Davon Jonas | Ring My Barber client</span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    name: "SalesOnlineBooking",

    data: function () {
        return {};
    },
    mounted: function () {},
    computed: {},
};
</script>
