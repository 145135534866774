<template>
    <!--Mobile apps section-->
    <section class="mobile-apps">
        <!--Section main container-->
        <div class="section-main-wrapper">
            <!--Header title-->
            <div class="heading-wrapper">
                <h2>Mobile Apps</h2>
            </div>
            <!--Sub title and text-->
            <div class="text-content">
                <p class="sub-title">Delight Your Clients with the Free, User-Friendly Booking App</p>
            </div>
            <!--Review message-->
            <div class="rating-message-wrapper rating-review-wrapper">
                <div class="image-customer item-span-two-rows">
                    <img src="../../assets/images/rating-reviews.png" alt="Rating & Reviews" />
                </div>
                <p class="text">
                    Our mobile app is a win-win for professionals and clients. Your clients can quickly schedule
                    appointments, send messages, leave notes, make payments, and leave tips, all within the app. This
                    convenience enhances their experience and simplifies your workflow, making it an invaluable tool for
                    elevating your service and client satisfaction.
                </p>
                <div class="customer-message padding-left">
                    <div class="ring-image">
                        <img src="../../assets/images/rms-rounds.png" alt="RMS Image" />
                    </div>
                    <div class="message">
                        <p>
                            “Hey Tiffany, just wanted to drop you a quick note – I'm loving this app. It's super easy to
                            book and pay right from my phone. Makes everything so much simpler for me. Big thanks for
                            adding this, it's a real help”
                            <span>- Jenny M | Ring My Stylist client</span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    name: "SalesMobileApp",

    data: function () {
        return {};
    },
    mounted: function () {},
    computed: {},
};
</script>
