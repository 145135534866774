export default {
    install: function (Vue) {
        let allOptions = {
            "sales.ringmystylist.com": {
                app_id: 1,
                profile_url: "https://www.ringmystylist.com/stylist-profile/",
                short_url: "https://ringmy.pro/",
                site_url: "https://www.ringmystylist.com/",
                blog_url: "https://www.ringmystylist.com/blog",
                help_url: "https://www.ringmystylist.com/support",
                terms_url: "https://www.ringmystylist.com/terms-and-conditions",
                privacy_url: "https://www.ringmystylist.com/privacy-policy",
                refund_url: "https://www.ringmystylist.com/support/refund-policy/",
                contact_url: "https://www.ringmystylist.com/contact",
                booking_url: "https://book.ringmystylist.com/",
                app_name: "Ring My Stylist",
                app_title: "Online Appointments Booking System for Beauty Professionals and Salons",
                user_role_name: "Stylist",
                profession_id: 0,
                related_professions: { 2: 24 },
                subscriptionPrice: 9.99,
                subscriptionPriceReferral: 5,
                subscriptionPriceYear: 96.99,
                subscriptionPriceYearReferral: 48.99,
                email: "info@ringmystylist.com",
                instagram: "ringmystylist",
                defaultColor: "#00cab3",
                appstore_url: "https://itunes.apple.com/us/app/ring-my-stylist/id1250537446",
                googleplay_url: "https://play.google.com/store/apps/details?id=com.ringmystylist.app",
            },
            "sales.ringmybarber.com": {
                app_id: 2,
                profile_url: "https://www.ringmybarber.com/barber-profile/",
                short_url: "https://ringmy.pro/",
                site_url: "https://www.ringmybarber.com/",
                blog_url: "https://www.ringmybarber.com/blog/",
                help_url: "https://www.ringmystylist.com/support/",
                terms_url: "https://www.ringmystylist.com/terms-and-conditions",
                privacy_url: "https://www.ringmystylist.com/privacy-policy",
                refund_url: "https://www.ringmystylist.com/support/refund-policy/",
                contact_url: "https://www.ringmybarber.com/contact/",
                booking_url: "https://book.ringmybarber.com/",
                app_name: "Ring My Barber",
                app_title: "Online Appointments Booking System for Barbers and Barbershops",
                user_role_name: "Barber",
                profession_id: 2,
                related_professions: { 2: 24 },
                subscriptionPrice: 9.99,
                subscriptionPriceReferral: 5,
                subscriptionPriceYear: 96.99,
                subscriptionPriceYearReferral: 48.99,
                email: "info@ringmybarber.com",
                instagram: "ringmybarber",
                defaultColor: "#b40024",
                appstore_url: "https://itunes.apple.com/us/app/ring-my-barber-appointments/id1451800946",
                googleplay_url: "https://play.google.com/store/apps/details?id=com.ringmystylist.app.rmb",
            },
            default: {
                app_id: 1,
                profile_url: "https://www.ringmystylist.com/stylist-profile/",
                site_url: "https://www.ringmystylist.com/",
                blog_url: "https://www.ringmystylist.com/blog",
                help_url: "https://www.ringmystylist.com/support",
                terms_url: "https://www.ringmystylist.com/terms-and-conditions",
                privacy_url: "https://www.ringmystylist.com/privacy-policy",
                refund_url: "https://www.ringmystylist.com/support/refund-policy/",
                contact_url: "https://www.ringmystylist.com/contact",
                booking_url: "https://book.ringmystylist.com/",
                short_url: "https://s.ringmy.pro/",
                app_name: "Ring My Stylist",
                app_title: "Online Appointments Booking System for Beauty Professionals and Salons",
                user_role_name: "Stylist",
                profession_id: 0,
                related_professions: { 2: 24 },
                subscriptionPrice: 9.99,
                subscriptionPriceReferral: 5,
                subscriptionPriceYear: 96.99,
                subscriptionPriceYearReferral: 48.99,
                email: "info@ringmystylist.com",
                instagram: "ringmystylist",
                defaultColor: "#b40024",
                appstore_url: "https://itunes.apple.com/us/app/ring-my-stylist/id1250537446",
                googleplay_url: "https://play.google.com/store/apps/details?id=com.ringmystylist.app",
            },
        };

        let options = allOptions[window.location.host] ? allOptions[window.location.host] : allOptions.default;
        Vue.prototype.generalOptions = options;
    },
};
