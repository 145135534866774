<template>
    <!--Payments section-->
    <section class="payments">
        <!--Section main container-->
        <div class="section-main-wrapper no-background">
            <!--Header title-->
            <div class="heading-wrapper">
                <h2>Payments</h2>
            </div>
            <!--Sub title and text-->
            <div class="text-content">
                <p class="sub-title">We Offer Both Stripe and Square for Secure Online Transactions</p>
                <p class="text">
                    Offering both Stripe and Square, we provide our clients with secure and flexible online payment
                    options. Whether for deposits, cancellations, no-shows, service payments, or tips, choose the
                    platform that suits your business needs. Our integration with these leading services ensures smooth,
                    secure transactions for you and your clients.
                </p>
            </div>
            <!--Review message-->
            <div class="rating-message-wrapper">
                <div class="image-customer cards-logo-wrapper">
                    <div class="stripe-square">
                        <img src="../../assets/images/square-stripe.png" alt="Square Stripe Logos" />
                    </div>
                    <div class="cards"><img src="../../assets/images/cards.png" alt="Cards Logo" /></div>
                </div>
                <div class="customer-message">
                    <div class="ring-image">
                        <img src="../../assets/images/rmb-rounds.png" alt="RMB Image" />
                    </div>
                    <div class="message">
                        <p>
                            “I absolutely love the online payment feature. It makes transactions so smooth, and the
                            option for clients to leave tips is fantastic. It really adds to the overall professionalism
                            and convenience of the service.”
                            <span>- Nick Vicks | Barber</span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    name: "SalesPayments",

    data: function () {
        return {};
    },
    mounted: function () {},
    computed: {},
};
</script>
