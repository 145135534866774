<template>
    <!--Ring My Stylist Ring My Barber Ring My Loctician buttons-->
    <div class="get-started-btns-wrapper">
        <div class="get-started-btns">
            <div
                class="rms-rmb-rml"
                v-for="choice in ringChoices"
                @click="selectChoice(choice.id)"
                :key="choice.id"
                :class="{ [choice.className]: true, active: choice.id === salesSelectedApp }"
            >
                <button>{{ choice.title }}</button>
            </div>
        </div>
    </div>
</template>
<script>
import store from "./../../services/vuex.js";

export default {
    name: "SalesRmsRmbRmlButtons",

    data: function () {
        return {
            ringChoices: [
                { id: 1, title: "Ring My Stylist", className: "rms" },
                { id: 2, title: "Ring My Barber", className: "rmb" },
                { id: 5, title: "Ring My Loctician", className: "rml" },
            ],
        };
    },
    mounted: function () {
        this.setDefaultId();
    },
    computed: {
        salesSelectedApp: {
            get: function () {
                return store.state.salesSelectedApp;
            },
            set: function (value) {
                store.commit("setSalesSelectedApp", value);
            },
        },
    },
    methods: {
        selectChoice(id) {
            this.salesSelectedApp = id;
        },

        setDefaultId() {
            this.salesSelectedApp = this.generalOptions.app_id;
        },
    },
};
</script>
