<template>
    <div class="sales-signup-page">
        <!--Sales header-->
        <sales-header></sales-header>
        <!--Account sign up-->
        <sales-account-signup></sales-account-signup>
        <!--Sales footer-->
        <sales-footer></sales-footer>
    </div>
</template>
<script>
import SalesHeader from "./sales/sales_header.vue";
import SalesAccountSignup from "./sales/sales_account_signup.vue";

import SalesFooter from "./sales/sales_footer.vue";

export default {
    name: "SalesSignup",

    data: function () {
        return {};
    },
    mounted: function () {},
    computed: {},
    methods: {},
    components: {
        SalesHeader,
        SalesAccountSignup,
        SalesFooter,
    },
};
</script>
