<template>
    <!--Online portfolio section-->
    <section class="online-portfolio">
        <!--Section main container-->
        <div class="section-main-wrapper no-background">
            <!--Header title-->
            <div class="heading-wrapper">
                <h2>Online Portfolio</h2>
            </div>
            <!--Sub title and text-->
            <div class="text-content">
                <p class="sub-title">Showcase Your Talent with a Unique Link to Your Exclusive Portfolio Page</p>
                <p class="text">
                    Boost your professional presence with our free online portfolio page. Showcase your services, bio,
                    products, and client reviews, along with portfolio images and policies. This digital showcase
                    enhances your visibility, attracts more clients, and helps build your brand effectively.
                </p>
            </div>
            <!--Review message-->
            <div class="rating-message-wrapper">
                <div class="image-customer"></div>
                <div class="customer-message span-two-column">
                    <div class="ring-image">
                        <img src="../../assets/images/rml-rounds.png" alt="RML Ring" />
                    </div>
                    <div class="message">
                        <p>
                            “Absolutely loving my portfolio page from Ring My Stylist. It's great to have a personal
                            website link for Instagram and to show clients. It really showcases my work beautifully.
                            Thanks, Ring My Stylist”
                            <span>- Nails Love Salon&Spa | Nail Technician</span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    name: "SalesOnlinePortfolio",

    data: function () {
        return {};
    },
    mounted: function () {},
    computed: {},
};
</script>
