<template>
    <!--Free access section-->
    <section class="free-access">
        <!--Section main container-->
        <div class="free-access-main-wrapper">
            <!--Section content-->
            <div class="inner-wrapper">
                <!--Section text-->
                <div class="free-access-text">
                    <h2 class="text-title">7 Days of Full Free Access</h2>
                    <p class="text-subtitle">$ <span>9.99</span> per month</p>
                    <p class="benefits">1 bookable calendar</p>

                    <p class="unlock-plan">
                        Unlock an Exclusive 20% Savings. Choose our Annual Plan and Pay Less for a Whole Year of
                        Unmatched Service.
                    </p>

                    <div class="get-started-wrapper">
                        <router-link class="sales-get-started" :to="{ path: '/get-started' }">Get started</router-link>
                    </div>
                </div>
                <!--Section formular-->
                <div class="formular">
                    <div v-if="!formSubmitted">
                        <div class="form-title">
                            <h2>Unsure about choosing our services? Have a question for us?</h2>
                        </div>
                        <form @submit.prevent="submitForm">
                            <div class="form-inputs">
                                <input
                                    v-model.trim="questionObj.name"
                                    type="text"
                                    name="Your name"
                                    :class="{ error: isValidatedField && questionObj.name === '' }"
                                    :placeholder="isValidatedField ? 'Please enter your name' : 'Your name'"
                                    required
                                />

                                <input
                                    v-model.trim="questionObj.email"
                                    type="email"
                                    name="Email"
                                    :class="{ error: isValidatedField && questionObj.email === '' }"
                                    :placeholder="isValidatedField ? 'Please enter your email' : 'Your email'"
                                    required
                                />
                            </div>
                            <textarea
                                v-model.trim="questionObj.message"
                                cols="30"
                                rows="10"
                                name="Message"
                                :class="{ error: isValidatedField && questionObj.message === '' }"
                                :placeholder="
                                    isValidatedField
                                        ? 'Please write your message'
                                        : 'Tell us what you are looking for or any questions you may have. We are here to provide the answers you need.'
                                "
                                required
                            ></textarea>
                            <div class="ask-us-wrapper">
                                <button type="submit" @click="validateInput()" class="sales-get-started">Ask us</button>
                            </div>
                        </form>
                    </div>
                    <div v-if="formSubmitted" class="submit-message">
                        <p>Thank You for Contacting Us.</p>
                        <p>Your message has been successfully submitted.</p>
                        <p>We'll be in touch soon.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import api from "./../../services/api_endpoints.js";

export default {
    name: "SalesFreeAccess",

    emits: ["section"],
    data: function () {
        return {
            questionObj: {
                name: "",
                email: "",
                message: "",
            },
            formSubmitted: false,
            isValidatedField: false,
        };
    },
    mounted: function () {},
    computed: {},
    methods: {
        submitForm() {
            if (!this.validateInput()) {
                return;
            }

            this.$http
                .post(api.actions.send_contact_form_email, {
                    app_id: this.generalOptions.app_id + "0",
                    name: this.questionObj.name,
                    email: this.questionObj.email,
                    message: this.questionObj.message,
                })
                .then(function (response) {
                    if (response.status === 200) {
                        this.formSubmitted = true;
                    }
                });
        },
        validateInput() {
            this.isValidatedField = true;

            for (const field in this.questionObj) {
                if (this.questionObj[field] === null || this.questionObj[field] === "") {
                    return false;
                }

                if (field === "email" && !this.isValidEmail(this.questionObj[field])) {
                    return false;
                }
            }
            return true;
        },
        isValidEmail(email) {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return emailRegex.test(email);
        },
    },
};
</script>
