export default {
    general: {
        logOut: "Log Out",
        stylistSelectLabel: "Stylist",
        next: "Next",
        prev: "Prev",
        skip: "Skip",
        continue: "Continue",
        client: "Client",
        date: "Date",
        services: "Services",
        stylist: "Stylist",
        time: "Time",
        confirm: "Confirm",
        andUp: "and up",
        minutes: "minutes",
        cancel: "Cancel",
        location: "Location",
        address: "Address",
        city: "City",
        state: "State",
        zip: "ZIP",
        active: "Active",
        members: "Team Members",
        invite: "Invite",
        name: "Name",
        categoryName: "Category Name",
        email: "Email",
        phone: "Phone",
        sendInvite: "Send Invite",
        save: "Save",
        addNew: "Add Service/Product",
        addNewCategory: "Add Category",
        price: "Price",
        duration: "Duration",
        group: "Group",
        category: "Category",
        restricted: "Restricted",
        users: "Users",
        min: "min",
        yes: "Yes",
        no: "No",
        freeFrom: "From",
        freeTo: "To",
        fullDay: "Full Day",
        loggenInAs: "You are logged in as",
        password: "Password",
        confirmPassword: "Confirm Password",
        logIn: "Login",
        rememberMe: "Remember me",
        forgot: "Forgot",
        noProfileYet: "No Profile Yet?",
        register: "Register",
        firstName: "First Name",
        lastName: "Last Name",
        backToLogin: "Back to Login",
        search: "Search",
        stylists: "Stylists",
        other: "Other",
        orderBy: "Order By",
        pending: "Pending",
        pendingGroupRequest: "You have a pending invitation to join team",
        on: "on",
        or: "Or",
        offeredBy: "Offered by",
        viewShopPage: "View Shop Page",
        status: "Status",
        inactive: "Inactive",
        remove: "Remove",
        saveChanges: "Save Changes",
        loading: "Loading settings...",
        add: "Add",
    },
    days: {
        sunday: "Sunday",
        monday: "Monday",
        tuesday: "Tuesday",
        wednesday: "Wednesday",
        thursday: "Thursday",
        friday: "Friday",
        saturday: "Saturday",
    },
    adminMenu: {
        dashboard: "Calendar",
        appointments: "Appointments",
        book: "Book",
        clientNotificationsSettings: "Notifications",
        completeCheckout: "Checkout",
        myClients: "Clients",
        team: "Shop & Team",
        shopDetails: "Shop Details",
        teamMembers: "Team Members",
        services: "Services",
        servicesCategories: "Service Categories",
        schedule: "Schedule",
        marketing: "Marketing",
        reports: "Reports",
        apptsReports: "Appts. Report",
        productsReports: "Products Reports",
        specialOffers: "Special Offers",
        pushNotifications: "Push Notifications",
        smsNotifications: "SMS | Text Messages",
        socialLinks: "Social Links",
        bookingWidget: "Booking Widget",
        workSettings: "Work Settings",
        general: "General",
        serviceHours: "Service Hours",
        businessLicense: "Business License",
        notifications: "Notifications",
        profile: "Profile",
        accountSettings: "Account Settings",
        paymentSettings: "Stripe & Square",
        subscriptionSettings: "Subscription",
        subscriptionDetails: "Subscription Details",
        referral: "Referral Program",
        paymentMethods: "Payment Methods",
        paymentsLog: "Payments Log",
        logout: "Logout",
        forgotPassword: "Forgot Password?",
        forgotPasswordText:
            "Please enter your email address and we will send you an email with a link to reset your password.",
        forgotPasswordSuccessText: "An email was sent to you where you can find the link to recover your password.",
        sendEmail: "Send Email",
        backToLogin: "Back To Login",
        recoverPassword: "Recover password",
        ok: "okay",
        changePassword: "Change Password",
        register: "Register Now",
        settings: "Settings",
        widgetSettings: "Widget Settings",
        widgetSnippet: "Widget Snippet",
        profileWidgetSettings: "Profile Widget Settings",
        contactUs: "Contact Us",
        payments: "Payments",
        creditCards: "Credit Cards",
        transactions: "Transactions",
        messageHistory: "Message History",
        paymentsDeposits: "Payments & Deposits",
        feesAndPolicies: "Fees & Policies",
        refunds: "Refunds",
        deposits: "Deposits",
        reschedule: "Reschedule",
        cancellationNoshow: "Cancellation / No Show",
        cancellation: "Cancellation",
        perClientRestrictions: "Per Client Restrictions",
        agesAndUp: "Ages %age% and up ONLY",
        ageLimit: "Age limit",
        policySummary: "Policy Summary",
        portfolioPictures: "Portfolio Pictures",
    },
    book: {
        title: "Book",
        additionalServices: "Book Additional service(s)",
        noStylistsAvailable: "No stylists available for this service",
        fullyBooked: "The stylist is fully booked",
        specialEvent: "Special Event",
        notes: "Notes",
        reminders: "REMINDERS (DAYS BEFORE APPOINTMENT)",
        bookButton: "Book",
    },
    adminDashboard: {
        title: "Calendar",
        clientTitle: "My Appointments",
        refresh: "Refresh",
        appointmentDetails: "Appointment Details",
        confirm: "Confirm",
        reschedule: "Reschedule",
        bookAgain: "Book Again",
        preventRescheduleMessage: "The stylist disabled the reschedule of appointments!",
        cancel: "Cancel",
        save: "Save",
        back: "Back",
        addListServicesTitle: "Services",
        addListProductsTitle: "Products",
        details: {
            services: "Services",
            notes: "Notes",
            with: "With",
            from: "From",
            to: "To",
            duration: "Duration",
            price: "Price",
            status: "Status",
            cofirmQuestion: "Are you sure?",
            cofirmYes: "Yes, cancel it!",
            cofirmNo: "No, keep it",

            titleConfirmed: "Confirmed!",
            msgConfirmed: "Your appointment has been confirmed.",
            titleCanceled: "Canceled!",
            msgCanceled: "",
            deposit: "Deposit",
            payment: "Payment",
            cancelRecurring: "Cancel Recurring",
            cofirmModifyApptYes: "Yes",
            modifyMyAppointment: "Are you sure you want to modify this appointment?",
            cofirmModifyApptNo: "No",
            cofirmYesGeneral: "Yes",
            cofirmNoGeneral: "No",
            cancelAppointment: "Cancel appointment",
            cancelAppointmentText: "Why do you want to cancel?",
            cancelAppointmentYes: "Canceled",
            cancelAppointmentNo: "Don't cancel",
            cancelAppointmentNoShow: "No Show",
        },
        dateModel: {
            addClient: "+ Add Client",
            addService: "+ Add Service / Product",
            serviceCost: "Service Cost",
            note: "Note",
            duration: "Duration",
            notes: "Notes",
            optional: "optional",
            clientConfirmed: "Client confirmed this appt.",
        },
    },
    clients: {
        title: "Clients",
        cofirmYes: "Yes, delete it!",
        cofirmNo: "No, keep it",
        addNew: "Add New Client",
        edit: "Edit",
        appointment: "appt.",
        last_appointment_on: "Last appt. on",
        upcoming_appointment_on: "upcoming appt. on",
        all_first_letters: "All",
        showBlockedClientsTooltip: "Click to load the list of your blocked clients.",
        showUnblockedClientsTooltip: "Click to load the regular list of your clients.",
        importClients: "Import Clients",
        exportClients: "Export Clients",
        block: "Block",
        unblock: "Unblock",
        clients: "clients",
        active: "active",
        blocked: "blocked",
        appointments_history: "Appointments History",
        no_appointments_history: "No appointments history",
        payments_history: "Payments History",
        no_payments_history: "No payments has been made",
        import_clients: "Import Clients",
        import_clients_text:
            "We can easily import your list of clients. Upload your file and we will process it in less than 24 hours",
        searchNotFourdMessage: "Your search returned no results.",
        noClientsMessage: "You don't have any clients yet.",
        undoMergeClients: "Undo Merge Clients",
        mergeYes: "I understand",
        mergeNo: "No",
    },
    notifications: {
        reloadDefault: "Reload Default",
        reloadAllDefaults: "Reload :language Defaults",
        notificationsSettings: "Notifications Settings",
        sendTest: "Send Test",
        typeSMS: "SMS",
        typePush: "Push",
        copiedToClipboardMessage: "Successfully copied to Clipboard!",
        notifyClientsTextPart1:
            "An SMS and/or a Push Notification will be sent to the client when you schedule, reschedule, cancel or confirm an appointment.",
        notifyClientsTextPart2: "The content is limited to 255 characters.",
        notifyClientsText2:
            "An SMS and/or a Push Notification will be sent to you when the client schedules, reschedules, cancels or confirms an appointment.",
        notifySchedule: "When I schedule an appointment",
        notifyScheduleClient: "When the client schedules an appt.",
        notifyReschedule: "When I reschedule an appointment",
        notifyRecheduleClient: "When the client reschedules an appt.",
        notifyCancel: "When I cancel an appointment",
        notifyCancelClient: "When the client cancels an appt.",
        notifyConfirm: "When I confirm an appointment",
        notifyConfirmClient: "When the client confirms an appt.",
        globalVariablesText: "You can use the following text variables in your SMS body: (click to copy to clipboard)",
        globalVariable_CLIENT_FNAME: "Will be replaced with Client's first name.",
        globalVariable_CLIENT_LNAME: "Will be replaced with Client's last name.",
        globalVariable_MY_NAME: "Will be replaced with your name.",
        globalVariable_SALON: "Will be replaced with your Business Name (shop name).",
        globalVariable_APP_DATE_TIME: "Will be replaced with the appointment's date and time.",
        globalVariable_RESCHEDULE_DATE_TIME: "Will be replaced with the appointment's reschedule date and time.",
        globalVariable_CONFIRM_URL: "Will be replaced with the appointment's confirm URL.",
        globalVariable_DETAILS_URL: "Will be replaced with the appointment's details URL.",
        globalVariable_THIS_APP: "Will be replaced with this application name.",
        globalVariable_THIS_APP_LINK: "Will be replaced with the application download link.",
        Timezone: "Timezone:",
        selectTimezone: "Select Timezone",
        Language: "Language:",
        dateTimeFormat: "Date Time format:",
        merge_all_clients: "Merge all clients",
        unmerge_all_clients: "Unmerge all clients",
        activateNotificationHoursBeforeText: "Activate a notification __ hours before the scheduled appointment.",
        inviteToDownloadAppText:
            "For the newly added clients: send an SMS with an invitation to download the app for free and book appointments with me.",
        inviteOnScheduleToDownloadAppText:
            "For the clients that don't have the app installed add an invitation to download the app for free.",
        activateNotificationHoursBefore: "hour(s) before appointment",
        extraInviteToDownloadApp: "Add to Scheduled SMS.",
        inviteToDownloadApp: "Send the SMS",
        notificationSettings: "Appointment Notifications",
        chooseNotifications: "Please choose your preferred method of notifications.",
    },
    team: {
        title: "Shop Details",
        empQues: "How many employees are you adding to your team? *",
        contactTime: "A represent will contact you for details. What is the best time?",
        preferredDate: "Preferred Date *",
        preferredTime: "Preferred Time *",
        contactNotice: "You will be contacted within 48 hours by a service technician",
        preferredName: "You will talk with",
        preferredPhone: "We will call",
        resendEmailInvite: "Resent Email",
        resendPhoneInvite: "Resend Phone",
        cancelInvite: "Cancel Invite",
        teamMembers: "Team Members",
        teamName: "Your team name",
        addNew: "Add New",
        addNewStaff: "Add Staff",
        inviteStaff: "Invite Staff",
        shopName: "Shop Name",
        expandYourTeam: "Expand your team",
    },
    teamMember: {
        headerMessage: "You are part of the %s team!",
        removeInfo: 'To exit the team, just click the "Remove From Team" button below.',
        removeButtonCTA: "Remove from Team",
        removeButtonCTALoading: "Removing from Team...",
    },
    services: {
        title: "Services",
        serviceCategories: "Service Categories",
        activeDays: "Active Days",
        serviceColor: "Service Color",
        timesBooked: "times booked",
        serviceRevenue: "service revenue",
        availableOn: "Available on",
        order: "Order",
        categoryOrder: "Category Order",
        name: "Service Name",
        description: "Description",
        edit: "Add/Edit Service",
        price_and_up: '"AND UP"',
        servicesPreview: "Services Preview",
        currencyDisplayPrices: "Settings",
        currency: "Currency",
        displayPrices: "Display prices",
        isProduct: "This is a product",
        isPrivate: "This is a private service",
        isPrivateDesc: "Private service that only you can see and add at checkout",
        image: "Image",
        uploadImage: "Upload image",
        changeImg: "Change image",
        changeImage: "Change",
        deleteImage: "Delete",
    },
    schedule: {
        general: "General Settings",
        serviceHours: "Service Hours",
        pause: "Pause between appointments",
        guests: "Guests allowed to book",
        timeSlot: "time slot booking",
        workDays: "Work days & business hours",
        hoursAdvance: "hours in advance",
        daysInFuture: "days in the future",
        daysInFutureNote: "* for one month available at a time, type in 30",
        from: "From",
        blockFrom: "Block From",
        blockTo: "Block To",
        onlineBooking: "Enable Online Booking from Portfolio page",
        onlineBookingYes: "People can book you from your portfolio page",
        onlineBookingNo: "People can NOT book you from your portfolio page",
        porfolioPage: "YourPortfolioPage",
        blockOut: "BLOCK OUT (vacation days or time off)",
        blockOutUrl: "Click here to set the dates and times",
        confirmAppt: "Would you like to enable automatic confirmation of clients' appointments?",
        confirmApptText:
            "If you prefer to have appointments with your clients automatically confirmed, turn this setting to",
        confirmApptTex2: "However, if you want to manually confirm appointments, switch to",

        preventClients: "Allow clients to book?",
        preventClientsText: "If you wish your clients to book appointments with you, ensure this is set to",
        preventClientsText2: "Stop clients from booking their own appointments",

        preventClientsReschedule: "Allow clients to reschedule?",
        preventClientsRescheduleText:
            "If you wish for your clients to be able to reschedule appointments, ensure that this is set to",
        preventClientsRescheduleText2: "Otherwise, switch it on",

        acceptNewClients: "Are you accepting new clients?",
        acceptNewClientsText: "If you're open to enrolling new clients, keep this option set to",
        acceptNewClientsText2: "allowing only existing clients to schedule appointments",
        otherwise: "Otherwise, switch it on",
        acceptNewClientsOffText1: "Message for ",
        acceptNewClientsOffText2: "new",
        acceptNewClientsOffText3: " clients who will try to book:",
        acceptNewClientsOffMessage:
            "Not accepting new clients. Only book if you are an existing client of mine. Thank you!",
        acceptNewClientsOffLinkText: "Click here to see how it looks like",

        editNotifications: "Notify Clients",
        notifyClients: "NOTIFY CLIENTS",
        notificationsUrl: "Click here to set the notifications",
        notifyClientsText:
            "An SMS or Push Notification will be sent to the client when you schedule, reschedule or cancel an appointment.",
        notifyClientsInviteText:
            "When you add a new client, we send an SMS with an invitation to download the app for free and book appointments.",
        notifySchedule: "When I schedule an appointment",
        notifyRechedule: "When I reschedule an appointment",
        notifyCancel: "When I cancel an appointment",
        notifyInvite: "Send new clients a link to the app",
        inviteToDownloadAppText:
            "For the newly added clients: send an SMS with an invitation to download the app for free and book appointments with me.",
        inviteToDownloadApp: "Send the SMS",
        exceptions: "Exceptions",
        noteClients: "Notes - for your clients",
        shortNoteClients: "You can type a short note for your clients. The note will be visible under",
        yourPortfolioPage: "your portfolio page",
        underWorkingHoursSection: ", under Working Hours section.",
        changeHours: "Change Hours",
        saveChanges: "Save changes",
    },

    completeCheckout: {
        title: "Complete Checkout",
        headingParagraph: "This page enables you to conclude appointments for clients and mark them as 'checked out.'",
        checkedOut: "All appointments have been checked out.",
        noApptFound: "Your search returned no results.",
    },
    marketing: {
        write: "Write your message",
        write_content: "Message will be sent as push notifications. There is no cost involved.",
        write_content_sms: "The message will be sent as an SMS to your clients’ mobile devices.",
        write_content_email: "Message will be sent as email.",
        sendTo: "Send To",
        sendToOptToday: "With open appts. Today ",
        sendToOptTomorrow: "With open appts. Tomorrow ",
        sendToOptWeek: "With open appts. This week ",
        sendToOptNextWeek: "With open appts. Next week ",
        sendToOptOpen: "All clients with open appt ",
        sendToOptAll: "All clients ",
        sendToSalonAll: "All SALON clients ",
        send: "Send",
        messageLast: "Last you sent",
        resend: "Resend",
        showOffer: "Show offer",
        offerWrite: "Write your offer",
        editOffer: "Edit your offer",
        restrict: "Restrict offer to",
        saveOffer: "Save offer",
        socialLinks: "Social Links",
        socialLinksContent: "These social links will appear in your portfolio page",
        testLink: "Test Link",
        facebook: "Facebook",
        twitter: "Twitter",
        instagram: "Instagram",
        yelp: "Yelp",
        tik_tok: "Tik Tok",
        website: "Website",
        save: "Save",
        cancel: "Cancel",
        selectType: "Select the type",
        pushNotification: "Push Notification (requires the app to be installed on clients' devices)",
        smsTextNotifications: "SMS | Text notifications",
        sms: "SMS",
        email: "Email",
        available: "available",
        terms: "Terms and Conditions",
        agreeTerms: "I agree with the terms and conditions",
        preview: "Preview",
        buy_preview: "Buy & Preview",
        history: "History",
        transactionsSummary: "Transactions Summary",
        manualPayment: "Manual Payment",
        autoreloadSMS: "Auto Reload / SMS",
        specialOffers: "Special Offers",
        pushNotifications: "Push Notifications",
        weAreLoadingYourAccountWith: "We are loading your account with",
        freeTextMessage:
            "free text messages (SMS) every month on the subscription anniversary day. You can purchase extra text messages for",
        messageText: "message text",
        textMessagePreview: "Text message preview",
        seeBelowWhatYouAreAboutToSend: "see below what you are about to send",
        theMessageWillBeSentTo: "The message will be sent to",
        ofYourClients: "of your clients",
        itWillConsume: "It will consume",
        messagesFromThe: "messages from the",
        youHaveAvailable: "you have available",
        goBack: "Go Back",
        number: "Number",
        expiration: "Expiration",
        type: "Type",
        default: "Default",
        defaultForSMSPurchase: "Default for SMS purchase",
        delete: "Delete",
        addCard: " Add Card",
        addNewCard: "Add New Card",
        smsAmount: "SMS amount",
        noCreditCardOnFile: "No credit card on file",
        pay: "Pay",
        autoReload: "Auto reload",
        whenSmsBalanceIsBelow: "When SMS balance is below",
        smsMessagesAreLimited: "SMS messages are limited to 160 characters per message",
        youCanOnlyUseTextMessages: "You can only use text messages and emails within the application",
        unlessWeStateInWritingOtherwise: "Unless we state in writing otherwise, payments are non-refundable",
        theRemainingSmsMessagesAreNotRefundable:
            "If you stop using the app, the remaining SMS messages are not refundable",
        theRemainingEmailsAreNotRefundable: "If you stop using the app, the remaining Emails are not refundable",
        costPerSms: "Cost per SMS (text message) is",
        costPerEmail: "The cost per EMAIL is $0.0015. We offer you 1000 free EMAILS to start with (worth $1.5)",
        emailMessagesSent: "Email messages sent",
        messagesRemain: "messages remain",
        noTransactionsSummary: "No transactions summary",
        smsNotifications: "SMS | Text Messages",
        buy: "Buy",
        addAtLeastOnePaymentMethod: "Add at least one payment method",
    },
    freeTime: {
        title: "Free Time",
        addNew: "Add New",
        noFreeDays: "Click +Add New to block out a day.",
        edit: "Edit",
    },
    portfolio: {
        portfolioUrl: "Your Online Portfolio URL",
        businessAddress: "Business Address",
        bio: "About Us",
        address: "Address",
        state: "State",
        city: "City",
        zip: "Zip",
        pictures: "Portfolio Pictures",
        confirmQuestion: "Are you sure?",
        confirmYes: "Yes",
        confirmNo: "No, Cancel",
        country: "Country",
        businessNameHeader: "Business Name (shop name)",
        business_name: "Name",
        title: "Portfolio",
        copyUrl: "Copy URL",
        view: "View",
        share: "Share",
        mailInfo: "Check out my portfolio and book your next appointments",
        currency: "Currency",
        business_phone: "Business phone",
        alias: "Alias",
    },
    accounts: {
        profession: "Profession",
        firstName: "First Name",
        lastName: "Last Name",
        email: "Email",
        phone: "Phone",
        zip: "Zip",
        password: "Password",
        resetPassword: "Reset password",
        newPassword: "New Password",
        enterResetCode: "Enter Reset Code",
        cellPhone: "Cell Phone",
        newCellNumber: "New Cell Number",
        avatar: "Your Profile Image",
        verificationCode: "Verification Code",
        viaStripe: "via Stripe ",
        viaPaypal: "via Paypal ",
        accessToken: "Access Token",
        viaSquare: "via Square ",
        appId: "Application Id",
        savePayment: "Save Payment Settings",
        acceptsCreditCards: "Accept credit cards, manage billing and manage refunds in a single platform.",
        enrolledStripe: "You are enrolled into Stripe online payments.",
        enrolledSquare: "You are enrolled into Square online payments.",
        notEnrolled: "You are not enrolled in accepting online payments.",
        engineeredGrowth: "A complete payments platform, engineered for growth",
        payAsYouGo: "Pay as you go",
        cardCharge: "3.9% + 30 cents per successful card charge. No setup, monthly, or hidden fees.",
        payOnlyForWhatYouUse: "Pay only for what you use.",
        bankAccountNumber: "Have your bank Account Number & Routing Number or your Debit Card ready.",
        payouts: "Payouts",
        accountWillArrive:
            "The first payout to your account will arrive in 7 days. After 7 days, payouts will be daily.",
        forMoreDetails: "For more details visit",
        connectToStripe: "Connect to Stripe",
        updateStripe: "Click here to Update your Stripe account",
        forFees: "For fees, payments, and payouts details, please check",
        instructions: "Tap the link bellow for instructions",
        retrievedApplication: "How to retrieve Application ID and Personal Access Token from Square account.",
    },
    search: {
        filters: {
            search: "Search",
            searchPlaceholder: "Service, Salon, Stylist, or Phone",
            searchPlaceholderRMB: "Service, Salon, Barber, or Phone",
            searchServiceLabel: "Service",
            searchStylistLabel: "Stylist",
            location: "Location",
            locationPlaceholder: "Find Location",
            locationDropdownTitle: "Enter Your City or Zip Code",
            locationDropdownButton: "Search",
            locationDropdownPlaceholder: "City or Zip Code",
            locationDropdownUseCurrent: "Use Current Location",
            date: "Date",
            datePlaceholder: "Date & Time",
            dateChooseDate: "Choose a Date",
            dateChooseTime: "Choose a Time",
            refineResults: "Refine Results",
            byPrice: "By Price",
            price: "Price",
            byCustomerReview: "By Customer Review",
            customerReview: "Customer Review",
            more: "More",
            less: "Less",
            clear: "Clear",
            up: "Up",
        },
        contactForm: {
            title: "Leave a Message",
            palceholder: "How can we help you?",
            buttonLabel: "Send Message",
            successMessage: "Thank You!",
        },
        listing: {
            like: "Like",
            rateIt: "Rate It",
            share: "Share",
            profile: "Profile",
            viewProfile: "View Profile",
            bookNow: "Book Now",
            portfolio: "Portfolio",
            featured: "Featured",
            sortBy: "Sort By",
            aplhabetAZ: "By Alphabet: A-Z",
            aplhabetZA: "By Alphabet: A-A",
            highestRated: "Highest Rated",
            loadMore: "Load More",
            searching: "Searching...",
            noProfilesFound: "No Profiles Found",
            noResultsFor: "No results for",
            tryADifferent: "Try a different",
        },
    },
    downloadApp: {
        errorMessage: "Please type the email or phone",
        successMessage: "Successfully sent",
        title: "Download {0}",
        subtitle: "Schedule your clients with ease today",
        emailPlaceholder: "Enter Email",
        phonePlaceholder: "Enter Phone",
        inputSeaprator: "Or",
        buttonLabel: "Send Link",
    },
    register: {
        createAccount: "Create Account",
        stylist_info: "Enjoy 1 year free Web Access - ",
        learn_more: "learn more",
        client_info: "is completely free of charge for clients to use",
        stylist_info_2: "Enjoy 30 days free then $9.99/mo",
        sms_optin:
            "I confirm that I want to receive text message notifications related to my appointments and regulatory compliance.",
    },
    payments: {
        title: "Payments",
        stripe: "Stripe",
        square: "Square",
        refunds: "Refunds",
        payouts: "Payouts",
        statements: "Statements",
        cancellationFees: "Cancellation Fees",
        perClientRestriction: "Per Client Restrictions",
        policySummary: "Policy Summary",
        eligibleTransactions: "View eligible transactions. Manage funds.",
        noTransactions: "No transactions",
        appointmentId: "Appointment ID:",
        refund: "Refund",
        thereAreNoFees:
            "* There are no fees to refund a charge, but the fees from the original charge are not returned.",
        yourCustomerSees:
            "* Your customer sees the refund as a credit aproximately 5-10 business days later, depending upon the bank.",
        refundCannotBeCanceled:
            "* Once issues, a refund cannot be canceled. Disputs and chargebacks aren't possible on credit card charges that are fully refunded.",
        email: "Email",
        statement: "Statement",
        loadMore: "Load More",
    },
    policy: {
        depositRule: "Deposit rule be visible and accepted by your clients at the appointment time.",
        clients: "Clients will be charged at the time of booking.",
        deposits: "Deposits",
        nonRefundableDeposit: "Non Refundable Deposit",
        depositPolicy: "Deposit Policy",
        cancelationRules: "Cancellation rules will be visible and accepted by your clients.",
        save: "Save",
        clientCanCancel: "Client can Cancel or Reschedule",
        hoursInAdvance: "hours in advance",
        fees: "Fees",
        noShow: "No Show",
        cancelAfter: "Cancel after",
        hours: "hours",
        cancellationPolicy: "Cancellation Policy",
        beingLate: "Being Late",
        min$: "min $",
        lateFee: "late fee",
        late: "Late",
        after: "After",

        minCancelledNoExeptions: "min CANCELLED NO EXCEPTIONS",
        rescheduleRules: "Reschedule rules will be visible and accepted by your clients.",
        clientCanReschedule: "Client can Reschedule",
        rescheduleAfter: "Reschedule after",
        reschedulePolicy: "Reschedule Policy",
        reschedule: "Reschedule",
        appointments: "appointments",
        depositCancellationPolicy: "Policy",
        applyDepositFee: "Apply deposit fee",
        applyCancellationFee: "Apply cancellation fee",
        applyLateFee: "Apply late fee",
        applyRescheduleFee: "Apply reschedule fee",
        onlyAges: "Only ages",
        andUpOnlyAreAllowed: "and up only are allowed.",
        thisIsASummary: "This is a summary of what you have enabled",
        edit: "Edit",
        policy: "Policy:",
        canCancel: "Can cancel",
        feesAfter: "fees after ",
        feesForNoShow: "fees for No Show",
        noResults: "Your search returned no results.",
        noClients: "No clients with policy OFF",
        isEnable: "You don't have anything enabled",
    },
    referral: {
        referredUsers: "Referred Users",
        titleSingular1: "Stylist",
        titlePlural1: "Stylists",
        titleSingular2: "Barber",
        titlePlural2: "Barbers",
        titleSingular3: "Groomer",
        titlePlural3: "Groomers",
        titleSingular5: "Loctician",
        titlePlural5: "Locticians",
        headline: " save together",
        getReferrerButtonLabel: "Get my Referrer URL",
        terms: "Referral Program Terms",
        disclaimers: "Important Disclaimers",
        termsText:
            "We retain the right to investigate the participation in the Referral Program for any fraudulent activities and take any measures to end them. We reserve the right to amend or suspend this Referral Program at any time, for any reason.",
        termsAccept: "Accept",
        termsDecline: "Decline",
        yourReferralCode: "Your Referral Code",
        yourReferralLink: "Your Referral Link",
        copy: "Copy",
        save: "Save",
        faq: "FAQ",
        whoCanIRefer: "Who can I refer?",
        youCanRefer: "You can refer friends or family who are not currently",
        customers: "customers",
        howManyRefereesCanIRefer: "How many referees can I refer?",
        thereIsNoLimit: "There is no limit to the number of referral offers you can send.",
        whatDoesMyRefereeNeed: "What does my referee need to do to redeem the referral?",
        tellYourReferees: "Tell your referees they must open the Referrer URL and follow the link instructions.",
        howLongDoesItTake: "How long does it take for the credit to be applied to my account?",
        theFirstMonthly: "The first monthly installment of $5 will be applied 4 weeks after your referee has joined",
        theFirstMonthlyEnd: "app and paid the first month subscription.",
        theSubsequent:
            "The subsequent $5installments will be applied for the next 5 months, as long as the account remains open, paid, and active during that time.",
        name: "Name",
        email: "Email",
        subscriptionActive: "Subscription Active",
        trial: "Trial",
        subscriptionCreated: "Subscription Created",
        firstPaymentDate: "First Payment Date",
        loadMore: "Load More",
        youHaveNoReferredUsers: "You have no referred users.",
    },
    payouts: {
        title: "Payouts",
        recentPayouts: "Recent Payouts",
        noPayouts: "No payouts",
        paypalEmail: "PayPal Email",
        currentBalance: "Current Balance",
        edit: "Edit",
        save: "Save",
        cancel: "Cancel",
        amount: "Amount",
        recipientEmail: "Recipient Email",
        paymentDate: "Payment Date",
        loadMore: "Load More",
    },
    businessLicense: {
        businessLicense: "Business License",
        addNewLicense: "Add New License",
        view: "View",
        delete: "Delete",
        noLicensesForThisStylist: "No licenses for this stylist",
        backToLicenses: "Back to licenses",
        licenseNumber: "License Number",
        firstName: "First Name",
        lastName: "Last Name",
        stateOfIssueCountry: "State of Issue / Country",
        licenseInformation: "License Information",
        images: "Images",
        saveLicense: "Save License",
        browse: "Browse...",
        save: "Save",
        cancel: "Cancel",
        addPhoto: "Add Photo",
    },

    subscription: {
        title: "Subscription",
    },

    contactUs: {
        within24:
            " Perhaps, you'd prefer to send us a message below. Our professional team get across to you within 24 hours.",
        getEmails: "We are always excited to get emails, so feel free to write to us at",
    },

    clientPayment: {
        addOneCard: "Please add a card.",
        addCard: "Add Card",
        stripeCard: "Stripe Card",
        loadingCards: "Loading cards...",
        squareCard: "Square Card",
        saveCard: "Save Card",
        saving: "Saving...",
        cancel: "Cancel",
        transactions: "Transactions",
        noTransactions: "No Transactions",
    },
};
