<template>
    <!--Transparent pricing section-->
    <div class="transparent-pricing">
        <h2>Transparent Pricing, Full Control Over Your Earnings</h2>
        <div class="pricing-content">
            <div class="pricing-wrapp" v-for="infoPricing in transparentPrincing" :key="infoPricing.id">
                <div class="checkmark-wrap">
                    <img src="../../assets/images/checkmark.png" alt="Check Mark Icon" />
                </div>
                <div class="pricing-text">
                    <p class="title">{{ infoPricing.title }}</p>
                    <p>
                        {{ infoPricing.info }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "SalesTransparentPricing",

    data: function () {
        return {
            transparentPrincing: [
                {
                    id: 0,
                    title: "100% Earnings Retained",
                    info: " Keep every cent from your bookings and all revenue generated through the Ring My Stylist Marketplace.",
                },
                {
                    id: 1,
                    title: "Freedom Without Fees",
                    info: "Forget about long-term commitments and hidden fees like setup, cancellation, or PCI-compliance charges. We believe in simplicity and transparency.",
                },
                {
                    id: 2,
                    title: "Tailored to Your Needs",
                    info: "Select only the add-ons that enhance your business. Pay for what you use, and save on what you don’t.",
                },
            ],
        };
    },
    mounted: function () {},
    computed: {},
};
</script>
