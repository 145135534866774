<template>
    <!--Customer support section-->
    <section class="customer-support">
        <!--Section main container-->
        <div class="section-main-wrapper">
            <!--Header title-->
            <div class="heading-wrapper">
                <h2>Customer Support</h2>
            </div>
            <!--Sub title and text-->
            <div class="text-content">
                <p class="sub-title">Rated 5 Stars by Our Customers: Your Assurance of Excellence</p>
                <p class="text">
                    We are committed to excellence in every interaction. Our team responds quickly to all inquiries,
                    valuing and incorporating your feedback to continuously improve our app. We believe in honesty and
                    transparency, openly communicating about any issues and resolving them efficiently. This dedication
                    to exceptional service and responsiveness is why our customers trust and highly rate us.
                </p>
            </div>
            <!--Review message-->
            <div class="rating-message-wrapper">
                <div class="image-customer">
                    <img src="../../assets/images/nr-one-customer-service.png" alt="Number One Customer Service" />
                </div>
                <div class="customer-message">
                    <div class="ring-image">
                        <img src="../../assets/images/rms-rounds.png" alt="RMS Image" />
                    </div>
                    <div class="message">
                        <p>
                            “Thank you. I love the app I have had great success with it and customer service responds
                            very fast where there are minor problems.”
                            <span>- Desiree Carpenter | Beautician</span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    name: "SalesCustomerSupport",

    data: function () {
        return {};
    },
    mounted: function () {},
    computed: {},
};
</script>
