<template>
    <!--Notifications section-->
    <section class="notifications">
        <!--Section main container-->
        <div class="section-main-wrapper">
            <!--Header title-->
            <div class="heading-wrapper">
                <h2>Notifications</h2>
            </div>
            <!--Sub title and text-->
            <div class="text-content">
                <p class="sub-title">Get Reminders and Notifications Directly as Push or Text Messages</p>
                <p class="text">
                    Enhance client communication with our notification system. Send direct messages, customized text
                    notifications, and ensure clients receive appointment reminders. This feature is key to reducing
                    no-shows and keeping your schedule on track, while also personalizing the client experience.
                </p>
            </div>
            <!--Review message-->
            <div class="rating-message-wrapper">
                <div class="image-customer"></div>
                <div class="customer-message span-two-column">
                    <div class="ring-image">
                        <img src="../../assets/images/rmb-rounds.png" alt="RMB Image" />
                    </div>
                    <div class="message">
                        <p>
                            “Just wanted to say a huge thanks for the reminder I got from the Ring My Barber app. It
                            came a day before my appointment, and honestly, I had completely forgotten about it. This
                            notification was a real lifesaver and kept me from missing my haircut. Really appreciate
                            it.”
                            <span>- DJ George</span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    name: "SalesNotifications",

    data: function () {
        return {};
    },
    mounted: function () {},
    computed: {},
};
</script>
