<template>
    <!--Sales main contianer-->
    <div class="sales-main-container">
        <!--Header Section-->
        <sales-header></sales-header>
        <!--Hero section-->
        <sales-hero></sales-hero>
        <!--Scheduling solution section-->
        <sales-scheduling-solution></sales-scheduling-solution>
        <!--Small teams section-->
        <sales-small-teams></sales-small-teams>
        <!--Customer support section-->
        <sales-customer-support></sales-customer-support>
        <!--Payments section-->
        <sales-payments></sales-payments>
        <!--Mobile apps section-->
        <sales-mobile-app></sales-mobile-app>
        <!--Calendar section-->
        <sales-calendar></sales-calendar>>
        <!--Notifications section-->
        <sales-notifications></sales-notifications>
        <!--Online portfolio section-->
        <sales-online-portfolio></sales-online-portfolio>
        <!--Online Booking section-->
        <sales-online-booking></sales-online-booking>
        <!--Marketing section-->
        <sales-marketing></sales-marketing>
        <!--Tutorials section-->
        <sales-tutorials></sales-tutorials>
        <!--Free access section-->
        <sales-free-access></sales-free-access>
        <!--Footer section-->
        <sales-footer></sales-footer>
    </div>
</template>
<script>
import SalesHeader from "./sales/sales_header.vue";
import SalesHero from "./sales/sales_hero.vue";
import SalesSchedulingSolution from "./sales/sales_scheduling_solution.vue";
import SalesSmallTeams from "./sales/sales_small_teams.vue";
import SalesCustomerSupport from "./sales/sales_customer_support.vue";
import SalesPayments from "./sales/sales_payments.vue";
import SalesMobileApp from "./sales/sales_mobile_app.vue";
import SalesCalendar from "./sales/sales_calendar.vue";
import SalesNotifications from "./sales/sales_notifications.vue";
import SalesOnlinePortfolio from "./sales/sales_online_portfolio.vue";
import SalesOnlineBooking from "./sales/sales_online_booking.vue";
import SalesMarketing from "./sales/sales_marketing.vue";
import SalesTutorials from "./sales/sales_tutorials.vue";
import SalesFreeAccess from "./sales/sales_free_access.vue";
import SalesFooter from "./sales/sales_footer.vue";

export default {
    name: "Sales",

    data: function () {
        return {
            isFixed: false,
        };
    },

    mounted: function () {
        window.addEventListener("scroll", this.handleScroll);
    },

    beforeDestroy() {
        window.removeEventListener("scroll", this.handleScroll);
    },
    computed: {},

    components: {
        SalesHeader,
        SalesHero,
        SalesSchedulingSolution,
        SalesSmallTeams,
        SalesCustomerSupport,
        SalesPayments,
        SalesMobileApp,
        SalesCalendar,
        SalesNotifications,
        SalesOnlinePortfolio,
        SalesOnlineBooking,
        SalesMarketing,
        SalesTutorials,
        SalesFreeAccess,
        SalesFooter,
    },

    methods: {},
};
</script>
