<template>
    <!--Footer section-->
    <section class="sales-footer">
        <div class="sales-footer-main-wrapper">
            <!--Facebook-->
            <div class="image-logo">
                <a href="https://www.facebook.com/ringmystylist" target="_blank"
                    ><img src="../../assets/images/facebook-32.png" alt="Facebook Logo"
                /></a>
            </div>
            <!--Youtube-->
            <div class="image-logo">
                <a href="https://www.youtube.com/@RingMyPro" target="_blank"
                    ><img src="../../assets/images/youtube.png" alt="Youtube Logo"
                /></a>
            </div>
            <!--Instagram-->
            <div class="image-logo">
                <a href="https://www.instagram.com/ringmystylist" target="_blank"
                    ><img src="../../assets/images/instagram.png" alt="Instagram Logo"
                /></a>
            </div>
            <!--Twitter-->
            <div class="image-logo">
                <a href="https://twitter.com/ringmystylist" target="_blank"
                    ><img src="../../assets/images/twitter-x.png" alt="Twitter Logo"
                /></a>
            </div>
            <!--LinkedIn-->
            <div class="image-logo">
                <a href="https://www.linkedin.com/company/ringmystylist/" target="_blank"
                    ><img src="../../assets/images/linkedin.png" alt="LinkedIn Logo"
                /></a>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    name: "SalesFooter",

    data: function () {
        return {};
    },
    mounted: function () {},
    computed: {},
};
</script>
