import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);

let initialState = {
    bookData: {
        id: 0,
        stylist_id: 0,
        services: [],
        date: "",
        hour: "",
        special_event: false,
        notes: "",
        reminders: [],
        reschedule_fee: 0,
        original_date: "",
        selected_service_qty: {},
        book_again: false,
        reschedule: false,
    },
    previewData: {
        date: "",
        stylistName: "",
        services: [],
    },
    currentBookSection: 1,
    serviceCategories: [],
    services: {},
    selectedServicesDuration: 0,
    freeHours: [],
    stylists: [],
    bookStylists: [],
    selectedStylist: null,
    selectedClient: null,
    userDataLoaded: false,
    isUserGroup: false,
    adminDetails: {},
    showClientsEditForm: false,
    groupMembers: [],
    paymentStylists: [],
    loadingPaymentStylists: false,
    squareCards: {},
    stripeCards: {},
    totalCards: 0,
    clientPaymentsTotal: 0,
    stylistPolicy: {
        age_limit: 0,
    },
    translation: {},
    localNotificationCount: 0,
    checkoutAppointentsCount: 0,
    hasPolicyEnabled: false,
    salesSelectedApp: 1,
    numberOfCalendars: 1,
};

export default new Vuex.Store({
    state: Vue.util.extend({}, initialState),
    mutations: {
        setBookData: function (state, payload) {
            state.bookData = payload;
        },
        setBookSection: function (state, payload) {
            state.currentBookSection = payload;
        },
        setServices: function (state, payload) {
            state.services = payload;
        },
        setServiceCategories: function (state, payload) {
            state.serviceCategories = payload;
        },
        setFreeHours: function (state, payload) {
            state.freeHours = payload;
        },
        setPreviewData: function (state, payload) {
            state.previewData = payload;
        },
        setStylists: function (state, payload) {
            state.stylists = payload;
        },
        setBookStylists: function (state, payload) {
            state.bookStylists = payload;
        },
        setSelectedStylist: function (state, payload) {
            state.selectedStylist = payload;
        },
        setSelectedClient: function (state, payload) {
            state.selectedClient = payload;
        },
        setIsUserGroup: function (state, payload) {
            state.isUserGroup = payload;
        },
        setSelectedServicesDuration: function (state, payload) {
            state.selectedServicesDuration = payload;
        },
        setAdminDetails: function (state, payload) {
            state.adminDetails = payload;
        },
        setShowClientsEditForm: function (state, payload) {
            state.showClientsEditForm = payload;
        },
        setPaymentStylists: function (state, payload) {
            state.paymentStylists = payload;
        },
        setLoadingPaymentStylists: function (state, payload) {
            state.loadingPaymentStylists = payload;
        },
        setStripeCards: function (state, payload) {
            state.stripeCards = payload;
        },
        setSquareCards: function (state, payload) {
            state.squareCards = payload;
        },
        setTotalCards: function (state, payload) {
            state.totalCards = payload;
        },
        setClientPaymentsTotal: function (state, payload) {
            state.clientPaymentsTotal = payload;
        },
        setStylistPolicy: function (state, payload) {
            state.stylistPolicy = payload;
        },
        setTranslation: function (state, payload) {
            state.translation = payload;
        },
        resetState(state) {
            for (let f in state) {
                Vue.set(state, f, initialState[f]);
            }
        },
        setLocalNotificationCount: function (state, payload) {
            state.localNotificationCount = payload;
        },
        setCheckoutAppointentsCount: function (state, payload) {
            state.checkoutAppointentsCount = payload;
        },
        setHasPolicyEnabled: function (state, payload) {
            state.hasPolicyEnabled = payload;
        },
        setSalesSelectedApp: function (state, payload) {
            state.salesSelectedApp = payload;
        },
        setSalesNumberOfCalendars: function (state, payload) {
            state.numberOfCalendars = payload;
        },
    },
    actions: {},
});
