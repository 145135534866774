<template>
    <!--What's included section-->
    <div class="whats-included">
        <h2>What’s included?</h2>
        <div class="advantages-wrapper">
            <!--Content from the left side of page-->
            <div class="left-side">
                <div class="advantage" v-for="advantage in leftArray" :key="advantage.id">
                    <div class="star">
                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 18 17" fill="none">
                            <path
                                id="Star 2"
                                d="M6.27874 2.90556C7.16397 0.333847 10.836 0.333846 11.7213 2.90556C12.1056 4.02202 13.1603 4.78354 14.3411 4.78354H14.6156C17.2503 4.78354 18.3048 8.18581 16.1321 9.6759C15.1377 10.3578 14.7233 11.6268 15.1158 12.7669C15.9725 15.2559 13.1691 17.4263 10.9982 15.9375L10.6286 15.684C9.64717 15.0109 8.35283 15.0109 7.37143 15.684L7.00178 15.9375C4.8309 17.4263 2.02748 15.2559 2.88425 12.7669C3.27669 11.6268 2.8623 10.3578 1.86793 9.6759C-0.304843 8.18581 0.749714 4.78354 3.38435 4.78354H3.65891C4.83967 4.78354 5.89442 4.02202 6.27874 2.90556Z"
                                fill="#00CDC1"
                                :class="appDetails[salesSelectedApp].appClass"
                            />
                        </svg>
                    </div>
                    <div>
                        <p>
                            <span>{{ advantage.title }}:</span> {{ advantage.info }}
                        </p>
                    </div>
                </div>
            </div>
            <!--Content from the right side of page-->
            <div class="right-side">
                <div class="advantage" v-for="advantage in rightArray" :key="advantage.id">
                    <div class="star">
                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 18 17" fill="none">
                            <path
                                id="Star 2"
                                d="M6.27874 2.90556C7.16397 0.333847 10.836 0.333846 11.7213 2.90556C12.1056 4.02202 13.1603 4.78354 14.3411 4.78354H14.6156C17.2503 4.78354 18.3048 8.18581 16.1321 9.6759C15.1377 10.3578 14.7233 11.6268 15.1158 12.7669C15.9725 15.2559 13.1691 17.4263 10.9982 15.9375L10.6286 15.684C9.64717 15.0109 8.35283 15.0109 7.37143 15.684L7.00178 15.9375C4.8309 17.4263 2.02748 15.2559 2.88425 12.7669C3.27669 11.6268 2.8623 10.3578 1.86793 9.6759C-0.304843 8.18581 0.749714 4.78354 3.38435 4.78354H3.65891C4.83967 4.78354 5.89442 4.02202 6.27874 2.90556Z"
                                fill="#00CDC1"
                                :class="appDetails[salesSelectedApp].appClass"
                            />
                        </svg>
                    </div>
                    <div>
                        <p>
                            <span>{{ advantage.title }}: </span> {{ advantage.info }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import store from "./../../services/vuex.js";

export default {
    name: "SalesWhatsIncluded",

    data: function () {
        return {
            leftArray: [
                {
                    id: 0,
                    title: "Complete Mobile & Web Business Solution",
                    info: "Streamline your operations with our integrated platform, suitable for both mobile and web.",
                },
                {
                    id: 1,
                    title: "Hassle-Free Data Transfer",
                    info: "We offer free data migration from any existing software you use.",
                },
                {
                    id: 2,
                    title: "Complimentary Shop Page",
                    info: "Feature your team with individual profiles on a free, customizable shop page.",
                },
                {
                    id: 3,
                    title: "Versatile Calendar Management",
                    info: "Tailor your schedule with Block Out, Unlock, Personal Time options, and more.",
                },
                {
                    id: 4,
                    title: "Unlimited Marketplace Listings",
                    info: "Benefit from free listing and unlimited daily bookings on the Ring My Stylist Marketplace.",
                },
                {
                    id: 5,
                    title: "Automated Notifications & Reminders",
                    info: "Keep clients engaged with automated appointment notifications and reminders.",
                },
                {
                    id: 6,
                    title: "Personalized Notification Messages",
                    info: "Customize your communication for a more personal touch with clients.",
                },
                {
                    id: 7,
                    title: "Efficient Online Booking",
                    info: "Enable clients to book appointments easily through Ring My Stylist.",
                },
                {
                    id: 8,
                    title: "Integrated Booking Widgets",
                    info: "Enhance your website with business and employee booking widgets at no extra cost.",
                },
                {
                    id: 9,
                    title: "In-Depth Reporting Tools",
                    info: "Access advanced reporting features to efficiently manage your business.",
                },
            ],
            rightArray: [
                {
                    id: 0,
                    title: "Client-Friendly Mobile App",
                    info: "Offer your clients the convenience of a free mobile app for easy bookings.",
                },
                {
                    id: 1,
                    title: "Marketing Communications",
                    info: "Send SMS and email marketing messages monthly to keep in touch with clients.",
                },
                {
                    id: 2,
                    title: "Round-the-Clock Support",
                    info: "Get continuous support with 24/7 chat and live phone support from 6 AM to 10 PM Pacific Time.",
                },
                {
                    id: 3,
                    title: "Comprehensive Training Sessions",
                    info: "Receive two group training sessions for your team and one-on-one training for each team member.",
                },
                {
                    id: 4,
                    title: "Secure Online Payments",
                    info: "Process transactions smoothly using integrated options like Stripe or Square.",
                },
                {
                    id: 5,
                    title: "Client Feedback System",
                    info: "Gather valuable insights with a built-in system for client reviews and feedback.",
                },
                {
                    id: 6,
                    title: "Customizable Services and Prices",
                    info: "Easily update and manage your service offerings and pricing.",
                },
                {
                    id: 7,
                    title: "Client Management Tools",
                    info: "Keep client information organized and accessible with advanced management features.",
                },
                {
                    id: 8,
                    title: "Promotional Campaign Support",
                    info: "Launch and manage promotional campaigns with our easy-to-use tools.",
                },
                {
                    id: 9,
                    title: "Continuous App Updates",
                    info: "Stay ahead with regular updates and new features added to the app.",
                },
            ],
            appDetails: {
                1: {
                    appName: "Ring My Stylist",
                    appClass: "rms",
                    appClassBtn: "rms-btn",
                },
                2: {
                    appName: "Ring My Barber",
                    appClass: "rmb",
                    appClassBtn: "rmb-btn",
                },
                5: {
                    appName: "Ring My Loctician",
                    appClass: "rml",
                    appClassBtn: "rml-btn",
                },
            },
        };
    },
    mounted: function () {},
    computed: {
        salesSelectedApp: {
            get: function () {
                return store.state.salesSelectedApp;
            },
            set: function (value) {
                store.commit("setSalesSelectedApp", value);
            },
        },
    },
};
</script>
