<template>
    <!--Calendar section-->
    <section class="sales-calendar">
        <!--Section main container-->
        <div class="section-main-wrapper no-background">
            <!--Header title-->
            <div class="heading-wrapper">
                <h2>Calendar</h2>
            </div>
            <!--Sub title and text-->
            <div class="text-content">
                <p class="sub-title">Day, 3-Day, Weekly, or Monthly Overviews to Plan Your Schedule Your Way</p>
            </div>
            <!--Review message-->
            <div class="rating-message-wrapper calendar-gap">
                <div class="image-customer item-span-two-rows">
                    <img src="../../assets/images/rms-app-calendar.png" alt="Appointment Calendar" />
                </div>
                <p class="text">
                    Tailor your schedule with our flexible calendar. View today's appointments, weekly schedules, or
                    future bookings at a glance. Choose between 1-day, 3-day, or weekly overviews. Our calendar not only
                    shows open slots and existing appointments but also your open and close days and important
                    notifications, keeping you perfectly organized and ahead of your schedule.
                </p>
                <div class="customer-message span-two-column">
                    <div class="ring-image">
                        <img src="../../assets/images/rml-rounds.png" alt="RML Ring" />
                    </div>
                    <div class="message">
                        <p>
                            “Love how this app's calendar shows free days, closed dates – it's super helpful for my
                            scheduling. And it syncs with my calendar too, making everything so much easier to manage”
                            <span>- Roe P | Loctician</span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    name: "SalesCalendar",

    data: function () {
        return {};
    },
    mounted: function () {},
    computed: {},
};
</script>
