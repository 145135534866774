<template>
    <!--Pricing FAQs-->
    <div class="pricing-faqs">
        <h2>FAQs</h2>
        <div class="faqs-wrapper">
            <div class="faq" :class="appDetails[salesSelectedApp].appClass">
                <p class="faq-title">How do I get started?</p>
                <p>
                    Access {{ appDetails[salesSelectedApp].appName }} effortlessly as it's web-based and available
                    globally at
                    <a :href="appDetails[salesSelectedApp].appCurrentUrl" target="_blank" class="app-current-url">{{
                        appDetails[salesSelectedApp].appUrl
                    }}</a
                    >. For mobile access, download the
                    {{ appDetails[salesSelectedApp].appName }}
                    app from the Apple App Store or Google Play Store, or use this link:
                    <a :href="appDetails[salesSelectedApp].appOpenUrl" target="_blank" class="app-open-url">{{
                        appDetails[salesSelectedApp].appOpenUrl
                    }}</a
                    >.
                </p>
            </div>
            <div class="faq" v-for="faq in faqsArray" :key="faq.id">
                <p class="faq-title">{{ faq.title }}</p>
                <p>
                    {{ faq.info }}
                </p>
            </div>
            <div class="get-started-wrapper">
                <router-link
                    class="sales-get-started"
                    :class="appDetails[salesSelectedApp].appClassBtn"
                    :to="{ path: '/sign-up' }"
                    >Get Started</router-link
                >
            </div>
        </div>
    </div>
</template>
<script>
import store from "./../../services/vuex.js";

export default {
    name: "SalesPricingFaqs",

    data: function () {
        return {
            appDetails: {
                1: {
                    appName: "Ring My Stylist",
                    appUrl: "RingMyStylist.com",
                    appCurrentUrl: "https://www.ringmystylist.com/",
                    appOpenUrl: "https://ringmystylist.com/openapp",
                    appClass: "rms",
                    appClassBtn: "rms-btn",
                },
                2: {
                    appName: "Ring My Barber",
                    appUrl: "RingMyBarber.com",
                    appCurrentUrl: "https://www.ringmybarber.com/",
                    appOpenUrl: "https://ringmybarber.com/openapp",
                    appClass: "rmb",
                    appClassBtn: "rmb-btn",
                },
                5: {
                    appName: "Ring My Loctician",
                    appUrl: "RingMyLoctician.com",
                    appCurrentUrl: "https://www.ringmyloctician.com/",
                    appOpenUrl: "https://ringmyloctician.com/openapp",
                    appClass: "rml",
                    appClassBtn: "rml-btn",
                },
            },
            faqsArray: [
                {
                    id: 0,
                    title: "Are there any percentage for online bookings?",
                    info: "Absolutely not! All the earnings from your online bookings are entirely yours. We don't charge any fees for acquiring new customers either.",
                },
                {
                    id: 1,
                    title: "What exactly is a 'bookable calendar'?",
                    info: "A bookable calendar refers to an individual's personal schedule within your business.",
                },
                {
                    id: 2,
                    title: "Is it easy to cancel my subscription?",
                    info: "Yes, you have the flexibility to cancel your subscription anytime, without any hassle or cancellation fees.",
                },
                {
                    id: 3,
                    title: "What's the typical setup duration?",
                    info: "Setting up can be quick and straightforward, often taking as little as 30 minutes. The exact time may vary depending on the number of staff, clients, services, and inventory details you need to add. Rest assured, we are here to help you every step of the way.",
                },
                {
                    id: 4,
                    title: "How does your free trial operate?",
                    info: "Enjoy a 7-day full free trial with full access to all features. If you need more time, a simple phone or email request can extend your trial to 30 days, ensuring you have ample opportunity to explore our platform.",
                },
            ],
        };
    },
    mounted: function () {},
    computed: {
        salesSelectedApp: {
            get: function () {
                return store.state.salesSelectedApp;
            },
            set: function (value) {
                store.commit("setSalesSelectedApp", value);
            },
        },
    },
};
</script>
