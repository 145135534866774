<template>
    <!--Header section-->
    <section class="sales-header" :class="{ fixed: isFixed }">
        <!--Section main container-->
        <div class="logo-login-signup-wrapper">
            <!--Section logo-->
            <div class="logo">
                <router-link :to="{ path: '/' }">
                    <img
                        v-if="generalOptions.app_id === 1"
                        src="../../assets/images/ring-my-stylist-salespage-logo.png"
                        alt="Ring My Stylist Logo"
                    /><img
                        v-if="generalOptions.app_id === 2"
                        src="../../assets/images/ring-my-barber-salespage-logo.png"
                        alt="Ring My Barber Logo"
                    />
                    <img
                        v-if="generalOptions.app_id === 5"
                        src="../../assets/images/ring-my-pro-logo.png"
                        alt="Ring My Pro Logo"
                    />
                </router-link>
            </div>
            <!--Section content-->
            <div class="login-signup-wrapper show-on-desktop">
                <!--Home-->
                <div class="sales-login">
                    <router-link class="home primary-btn extra-small mobile" :to="{ path: '/' }">Home </router-link>
                </div>
                <!--Log in-->
                <div class="sales-login">
                    <a class="login primary-btn extra-small mobile" :href="generalOptions.booking_url + 'app/login'"
                        >Log In
                    </a>
                </div>
                <!--Sign up-->
                <div class="sales-signup">
                    <router-link class="signup primary-btn extra-small mobile" :to="{ path: '/get-started' }"
                        >Sign Up</router-link
                    >
                </div>
            </div>
            <!--Mobile hamburger menu-->
            <hamburger class="show-on-mobile"></hamburger>
        </div>
        <!--Image that separate sections-->
        <div class="header-separator">
            <img src="../../assets/images/header-separator.png" alt="Separator" />
        </div>
    </section>
</template>
<script>
import store from "./../../services/vuex.js";
import Hamburger from "../hamburger.vue";

export default {
    name: "SalesHeader",

    data: function () {
        return { isFixed: false };
    },
    mounted: function () {
        window.addEventListener("scroll", this.handleScroll);
    },
    beforeDestroy() {
        window.removeEventListener("scroll", this.handleScroll);
    },
    computed: {
        numberOfCalendars: {
            get: function () {
                return store.state.numberOfCalendars;
            },
            set: function (value) {
                store.commit("setSalesNumberOfCalendars", value);
            },
        },
    },
    methods: {
        handleScroll() {
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            this.isFixed = scrollTop > 0;
        },
    },
    components: {
        Hamburger,
    },
};
</script>
