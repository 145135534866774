<template>
    <div
        id="app"
        v-bind:class="{
            'rms-app': generalOptions.app_id == 1,
            'rmb-app': generalOptions.app_id == 2,
            'rml-app': generalOptions.app_id == 5,
        }"
    >
        <div class="main-container full-width">
            <div class="router-container">
                <router-view></router-view>
            </div>
        </div>
        <Footer
            :class="{
                'admin-footer': is_admin,
            }"
        />
    </div>
</template>

<script>
import Footer from "./components/footer.vue";
import api from "./services/api_endpoints.js";
import store from "./services/vuex.js";

export default {
    name: "app",
    data: function () {
        return {
            is_admin: false,
        };
    },
    mounted: function () {
        document.title = this.generalOptions.app_title + " | " + this.generalOptions.app_name;

        this.$nextTick(function () {
            var link = document.querySelector("link[rel*='icon']");
            if (this.generalOptions.app_id != 1) {
                link.href = "/fvicon-" + this.generalOptions.app_id + ".png";
            } else {
                link.href = "/fvicon.png";
            }
        });

        var urlParams = new URLSearchParams(window.location.search);
        var params = Object.fromEntries(urlParams.entries());

        if (params.auth && params.profile) {
            window.location.href = window.location.origin + window.location.pathname;
        }
    },
    computed: {
        translation: {
            get: function () {
                return store.state.translation;
            },
            set: function (value) {
                store.commit("setTranslation", value);
            },
        },
    },
    methods: {
        getTranslation: function () {
            this.$http.get(api.actions.get_translation).then(
                function (response) {
                    if (typeof response.data == "object") {
                        this.translation = response.data;
                    }
                }.bind(this)
            );
        },
    },
    components: {
        Footer,
    },
};
</script>

<style lang="scss">
@import "./assets/scss/main.scss";
</style>
