<template>
    <!--Hero section-->
    <section class="sales-hero">
        <!--Section main container-->
        <div class="hero-container">
            <!--Section text-->
            <div class="hero-text-wrapper">
                <h1 class="hero-title">Online Appointment Booking System</h1>
                <h2 class="hero-info">
                    Our intuitive web and app software simplifies scheduling, reduces no-shows, and secures payments.
                </h2>
                <p class="hero-paragraph">#1 for Custom Support. Free setup & data import. Cancel anytime.</p>
            </div>
            <!--Section image-->
            <div class="hero-image">
                <img class="image" src="../../assets/images/girl-with-laptop.png" alt="Girl Looking at Appointments" />
            </div>
        </div>
    </section>
</template>
<script>
export default {
    name: "SalesHero",

    data: function () {
        return {};
    },
    mounted: function () {},
    computed: {},
};
</script>
