this.login = function (data, adminLogin) {
    let self = this;
    let request_data = {
        grant_type: "password",
        client_id: this.api.CLIENT_ID,
        client_secret: this.api.CLIENT_SECRET,
        username: data.email,
        password: data.password,
        scope: "",
    };
    return this.http.post(this.api.actions.login, request_data).then(
        function (response) {
            switch (response.status) {
                case 200:
                    if (typeof response.data == "undefined" || typeof response.data.access_token == "undefined") {
                        this.message.errorMessage(self.message.GLOBAL_MESSAGES.LOGIN_ERROR);
                    } else {
                        this.onLogin(response.data, data.remember);
                        this.setDeviceInfo();
                        this.getProfile(adminLogin);
                    }
                    break;
            }
        }.bind(this),
        function () {
            self.message.errorMessage(self.message.GLOBAL_MESSAGES.LOGIN_ERROR);
        }
    );
};

this.resetPassword = function (data) {
    let self = this;
    let request_data = {
        email: data.email,
        app_id: data.app_id,
        web: true,
    };
    return this.http.post(this.api.actions.reset_password, request_data).then(
        function (response) {
            switch (response.status) {
                case 200:
                    if (
                        typeof response.data == "undefined" ||
                        typeof response.data.error == "undefined" ||
                        response.data.error
                    ) {
                        return;
                    }
                    this.eventBus.$emit("reset_password_email_sent");
                    break;
            }
        }.bind(this),
        function () {
            self.message.errorMessage(self.message.GLOBAL_MESSAGES.RESET_PASS_ERROR);
        }
    );
};

this.setPassword = function (data) {
    let self = this;
    let request_data = {
        token: data.token,
        password: data.password,
        password_confirm: data.confirmPassword,
    };
    return this.http.post(this.api.actions.set_password, request_data).then(
        function (response) {
            switch (response.status) {
                case 200:
                    if (
                        typeof response.data == "undefined" ||
                        typeof response.data.error == "undefined" ||
                        response.data.error
                    ) {
                        return;
                    }
                    this.eventBus.$emit("change_password_success");
                    break;
            }
        }.bind(this),
        function () {
            self.message.errorMessage(self.message.GLOBAL_MESSAGES.RESET_PASS_ERROR);
        }
    );
};

this.onLogin = function (token, remember) {
    this.token = token;
    this.token.expire_time = new Date().getTime() / 1000 + this.token.expires_in;
    localStorage.setItem("token", JSON.stringify(this.token));
    localStorage.setItem("remember", remember);
    this.eventBus.$emit("user_logged_in");
};

this.checkClientRole = function () {
    if (this.user && this.user.role == "client") {
        return false;
    }

    return this.http.get(this.api.actions.get_stylist_subaccount_email).then(
        function (response) {
            switch (response.status) {
                case 200:
                    if (typeof response.data == "undefined" || typeof response.data.email == "undefined") {
                        this.message.errorMessage(this.api.REQUEST_ERROR);
                    } else {
                        this.login({
                            email: response.data.email,
                            password: this.user.email,
                        });
                    }
                    break;
            }
        }.bind(this),
        function () {
            return false;
        }
    );
};

this.register = function (data) {
    this.http.post(this.api.actions.register, data).then(
        function (response) {
            switch (response.status) {
                case 200:
                    if (typeof response.data != "undefined" && typeof response.data.user != "undefined") {
                        window.location = this.generalOptions.booking_url + "app/login";
                    }
                    break;
            }
        }.bind(this),
        function () {
            return false;
        }
    );
};

this.refreshToken = function (next) {
    let remember = localStorage.getItem("remember");

    if (!remember) {
        this.logout(false);
        next();
        return;
    }

    let self = this;
    let request_data = {
        grant_type: "refresh_token",
        client_id: this.api.CLIENT_ID,
        client_secret: this.api.CLIENT_SECRET,
        refresh_token: this.token.refresh_token,
        scope: "",
    };
    this.http.post(this.api.actions.login, request_data).then(
        function (response) {
            switch (response.status) {
                case 200:
                    if (typeof response.data == "undefined" || typeof response.data.access_token == "undefined") {
                        this.message.errorMessage(this.REQUEST_ERROR);
                    } else {
                        this.token = response.data;
                        this.token.expire_time = new Date().getTime() / 1000 + this.token.expires_in;
                        localStorage.setItem("token", JSON.stringify(this.token));
                        this.eventBus.$emit("token_refreshed");
                        next();
                    }
                    break;

                default:
                    this.message.errorMessage(this.REQUEST_ERROR);
                    break;
            }
        }.bind(this),
        function () {
            self.message.errorMessage(this.REQUEST_ERROR);
        }
    );
};

this.getProfile = function (adminLogin) {
    let self = this;
    this.http.get(this.api.actions.get_profile).then(
        function (response) {
            switch (response.status) {
                case 200:
                    if (typeof response.data == "undefined" || typeof response.data.user == "undefined") {
                        this.message.errorMessage(this.REQUEST_ERROR);
                    } else {
                        this.user = response.data.user;
                        this.user.details = {
                            group_owner: this.user.details.group_owner,
                            group_active: this.user.details.group_active,
                        };

                        localStorage.setItem("user", JSON.stringify(this.user));

                        if (!adminLogin) {
                            this.checkClientRole();
                        }

                        this.eventBus.$emit("user_profile_loaded");
                    }
                    break;

                default:
                    this.message.errorMessage(this.REQUEST_ERROR);
                    break;
            }
        }.bind(this),
        function () {
            self.message.errorMessage(this.REQUEST_ERROR);
        }
    );
};

this.setDeviceInfo = function () {
    this.http.post(this.api.actions.set_device_info, {
        app_id: this.generalOptions.app_id,
    });
};

this.logout = function (revoke_token) {
    if (revoke_token) {
        this.http.get(this.api.actions.logout);
    }
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("remember");
    localStorage.removeItem("calendarView");
    localStorage.removeItem("calendarDate");

    this.user = null;
    this.token = null;
    this.remember = 0;
    this.eventBus.$emit("user_logged_out");
    this.store.commit("resetState");
};

this.private_routes = ["/profile"];

this.private_admin_routes = [
    "/app",
    "/app/team",
    "/app/services",
    "/app/schedule",
    "/app/free-time",
    "/app/calendar",
    "/app/stylists",
    "/app/marketing",
    "/app/profile",
    "/app/payments",
    "/app/clients",
    "/app/subscription-payments",
    "/app/referral",
    "/app/contact-us",
    "/app/block-out",
    "/app/payments/credit-cards",
    "/app/payments/transactions",
    "/app/work-settings",
    "/app/work-settings/service-hours",
    "/app/work-settings/business-license",
    "/app/work-settings/notifications",
    "/app/marketing/special-offers",
    "/app/marketing/push-notifications",
    "/app/marketing/text-messages",
    "/app/marketing/social-links",
    "/app/marketing/booking-widget",
    "/app/payments/settings",
    "/app/payments/refunds",
    "/app/payments/statements",
    "/app/policy/deposits",
    "/app/policy/cancellation-noshow",
    "/app/policy/reschedule",
    "/app/policy/per-client-restrictions",
    "/app/policy/age-limit",
    "/app/policy/policy-summary",
    "/app/subscribe",
    "/app/payouts",
    "/app/reports/appts",
    "/app/reports/products",
];

try {
    this.user = JSON.parse(localStorage.getItem("user"));
    this.token = JSON.parse(localStorage.getItem("token"));
    this.remember = localStorage.getItem("remember");
    this.stylist_id = localStorage.getItem("stylist_id");
    this.stylist_alias = localStorage.getItem("stylist_alias");
} catch (e) {
    this.logout();
}

this.subscribe_redirect = false;
