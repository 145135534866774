<template>
    <!--Small teams section-->
    <section class="sales-small-teams">
        <!--Section main container-->
        <div class="small-teams-main-wrapper">
            <div class="small-teams-inner-wrapper">
                <!--Section heading-->
                <div class="small-teams-title">
                    <h2 class="title">
                        For Individuals, Small Teams, <br />
                        Busy Salons & SPA, and Large Businesses
                    </h2>
                </div>
                <!--Section content-->
                <div class="small-teams-wrapper">
                    <!--Left side content-->
                    <div class="left-side">
                        <div class="box-left-side">
                            <p class="box-heading-left-side">Customer Support</p>
                            <p class="box-text-left-side">
                                Rated 5 Stars by Our Customers: Your Assurance of Excellence
                            </p>
                        </div>
                        <div class="box-left-side">
                            <p class="box-heading-left-side">Payments</p>
                            <p class="box-text-left-side">
                                We Offer Both Stripe and Square for Secure Online Transactions
                            </p>
                        </div>
                        <div class="box-left-side">
                            <p class="box-heading-left-side">Mobile Apps</p>
                            <p class="box-text-left-side">
                                Delight Your Clients with the Free, User-Friendly Booking App
                            </p>
                        </div>
                        <div class="box-left-side">
                            <p class="box-heading-left-side">Calendar</p>
                            <p class="box-text-left-side">
                                Day, 3-Day, Weekly, or Monthly Overviews to Plan Your Schedule Your Way
                            </p>
                        </div>
                        <div class="box-left-side">
                            <p class="box-heading-left-side">Notifications</p>
                            <p class="box-text-left-side">
                                Get Reminders and Notifications Directly as Push or Text Messages
                            </p>
                        </div>
                        <div class="box-left-side">
                            <p class="box-heading-left-side">Online Portfolio</p>
                            <p class="box-text-left-side">
                                Showcase Your Talent with a Unique Link to Your Exclusive Portfolio Page
                            </p>
                        </div>
                        <div class="box-left-side">
                            <p class="box-heading-left-side">Online Booking</p>
                            <p class="box-text-left-side">
                                Enable Your Clients to View and Book Your Services Online with Ease
                            </p>
                        </div>
                        <div class="box-left-side">
                            <p class="box-heading-left-side">Marketing</p>
                            <p class="box-text-left-side">
                                Boost Your Marketing: Widget, Offers, Custom Messages, and More
                            </p>
                        </div>
                        <div class="box-left-side">
                            <p class="box-heading-left-side">Tutorials</p>
                            <p class="box-text-left-side">
                                A Dedicated Channel for Tutorials That Showcase Every Functionality
                            </p>
                        </div>
                    </div>
                    <!--Right side content-->
                    <div class="right-side">
                        <div class="box-right-side">
                            <div class="box-image">
                                <img src="../../assets/images/avatar-one.png" alt="Avatar Image" />
                            </div>
                            <div class="box-content">
                                <div class="box-heading-right-side">
                                    <p>
                                        .1Planton Blendz <br />
                                        Blendz Barbershop, PA
                                    </p>
                                </div>
                                <div class="box-rating-link">
                                    <div class="box-rating">
                                        <div class="star-wrapper">
                                            <img src="../../assets/images/star.png" alt="Star" />
                                            <img src="../../assets/images/star.png" alt="Star" />
                                            <img src="../../assets/images/star.png" alt="Star" />
                                            <img src="../../assets/images/star.png" alt="Star" />
                                            <img src="../../assets/images/star.png" alt="Star" />
                                        </div>
                                    </div>
                                    <div class="box-link">
                                        <a href="https://book.ringmybarber.com" target="_blank">Visit</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="box-right-side">
                            <div class="box-image">
                                <img src="../../assets/images/avatar-two.png" alt="Avatar Image" />
                            </div>
                            <div class="box-content">
                                <div class="box-heading-right-side">
                                    <p>
                                        Angel Sims <br />
                                        Cosmetologists, IN
                                    </p>
                                </div>
                                <div class="box-rating-link">
                                    <div class="box-rating">
                                        <div class="star-wrapper">
                                            <img src="../../assets/images/star.png" alt="Star" />
                                            <img src="../../assets/images/star.png" alt="Star" />
                                            <img src="../../assets/images/star.png" alt="Star" />
                                            <img src="../../assets/images/star.png" alt="Star" />
                                            <img src="../../assets/images/star.png" alt="Star" />
                                        </div>
                                    </div>
                                    <div class="box-link box-red">
                                        <a href="https://book.ringmystylist.com" target="_blank">Visit</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="box-right-side">
                            <div class="box-image">
                                <img src="../../assets/images/avatar-three.png" alt="Avatar Image" />
                            </div>
                            <div class="box-content">
                                <div class="box-heading-right-side">
                                    <p>
                                        Lutchie The Barber <br />
                                        Plaza Hair, WI
                                    </p>
                                </div>
                                <div class="box-rating-link">
                                    <div class="box-rating">
                                        <div class="star-wrapper">
                                            <img src="../../assets/images/star.png" alt="Star" />
                                            <img src="../../assets/images/star.png" alt="Star" />
                                            <img src="../../assets/images/star.png" alt="Star" />
                                            <img src="../../assets/images/star.png" alt="Star" />
                                            <img src="../../assets/images/star.png" alt="Star" />
                                        </div>
                                    </div>
                                    <div class="box-link">
                                        <a href="https://book.ringmybarber.com" target="_blank">Visit</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="box-right-side">
                            <div class="box-image">
                                <img src="../../assets/images/avatar-four.png" alt="Avatar Image" />
                            </div>
                            <div class="box-content">
                                <div class="box-heading-right-side">
                                    <p>
                                        Quasia Thigpen <br />
                                        Get Twisted, NC
                                    </p>
                                </div>
                                <div class="box-rating-link">
                                    <div class="box-rating">
                                        <div class="star-wrapper">
                                            <img src="../../assets/images/star.png" alt="Star" />
                                            <img src="../../assets/images/star.png" alt="Star" />
                                            <img src="../../assets/images/star.png" alt="Star" />
                                            <img src="../../assets/images/star.png" alt="Star" />
                                            <img src="../../assets/images/star.png" alt="Star" />
                                        </div>
                                    </div>
                                    <div class="box-link box-blue">
                                        <a href="https://book.ringmyloctician.com" target="_blank">Visit</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="experience"><h2>Experience Excellence on Us</h2></div>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    name: "SalesSmallTeams",

    data: function () {
        return {};
    },
    mounted: function () {},
    computed: {},
};
</script>
